<template>
    <div class="custom_head_cpe fixed_l_t">
        <img src="@/assets/logo.png" alt="" @click="$gotoPage('m-home')" class="logo">
        <div class="menu_btn flex_col flex_justify_between" :class="isShowMenu?'actived':''"
            @click="isShowMenu = !isShowMenu">
            <span></span>
            <span></span>
        </div>
        <div class="menu_wrap w_h_100 fixed_l_t" v-show="isShowMenu">
            <div class="mask w_h_100" @click="isShowMenu=false"></div>
            <div class="menu_content flex_col flex_align_start absolute_r_t">
                <div class="nav_item" v-for="(item, index) of navList" :key="index"
                    @click="handleSelectNav(item.pageName)">{{item.text}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data() {
            return {
                isShowMenu: false,
                navList: [
                    {
                        pageName: 'm-home',
                        text: '首页'
                    }, {
                        pageName: 'm-periphery',
                        text: '热门周边'
                    }, {
                        pageName: 'm-newProducts',
                        text: '当季新品'
                    }, {
                        pageName: 'm-about',
                        text: '关于解套'
                    }, {
                        pageName: 'm-news',
                        text: '实时新闻'
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'curPageName'
            ])
        },
        methods: {
            handleSelectNav(pagename) {
                this.$gotoPage(pagename)
                this.isShowMenu = false
                this.isShowMenu = false
            }
        },
    }
</script>

<style lang="scss" scoped>
    .custom_head_cpe {
        z-index: 10;
        width: 100%;
        height: 0;

        .logo {
            position: absolute;
            left: 32px;
            top: 20px;
            width: 78px;
            height: 24px;
        }

        .menu_btn {
            position: absolute;
            right: 32px;
            top: 10px;
            z-index: 10;
            width: 50px;
            height: 50px;

            &.actived {
                span {
                    &:first-child {
                        top: 23px;
                        transform: rotate(45deg);
                    }

                    &:last-child {
                        bottom: 23px;
                        transform: rotate(-45deg);
                    }
                }
            }

            span {
                position: absolute;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: #fff;
                transform: rotate(0);
                transition: all 0.5s ease;

                &:first-child {
                    top: 15px;
                }

                &:last-child {
                    bottom: 15px;
                }
            }
        }

        .menu_wrap {

            color: #fff;

            .mask {
                background-color: rgba(17, 17, 17, 0.4);
            }

            .menu_content {
                width: 535px;
                height: 100vh;
                font-size: 36px;
                background-color: rgba(17, 17, 17, 0.94);
                padding: 175px 0 0 96px;

                .nav_item {
                    margin-top: 70px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
</style>