<template>
    <div class="custom_foot_cpe flex_col flex_align_center">
        <img src="@/assets/logo.png" class="logo" alt="">
        <img src="@/assets/mobile/img/line_tag.png" class="line_tag" alt="">
        <img src="@/assets/mobile/img/tag_4.png" class="logo_icon" alt="">
        <div class="doit_text">DO IT FOR CHANGE,</br>expecting every brave attempt to make a difference.</div>
        <div class="third_party flex_align_center flex_justify_between">
            <img src="@/assets/JD.png" @click="$windowOpen(mobileJDShopHomeUrl)" alt="">
            <img src="@/assets/WX.png" @click="showBuyPop = true" alt="">
            <img src="@/assets/WB.png" @click="$windowOpen(mobileWBUrl)" alt="">
        </div>
        <div class="follow_wrap flex_align_center flex_justify_between">
            <img src="@/assets/pc/img/tag_2.png" class="tag_2" alt="">
            <span>FOLLOW US</span>
        </div>
        <div class="company_name">深圳市乐度健康科技有限公司</div>
        <div class="company_record">Copyright © 2021.乐度健康 All rights reserved.
            <a href="https://beian.miit.gov.cn/"><span>粤ICP备</span>2021165969号-1</a>
        </div>
        <a class="gongan flex_align_center"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902003153">
            <img src="@/assets/gongan.png" alt="">
            <span>粤公网安备 44030902003153号</span>
        </a>
        <div class="buyPop w_h_100 fixed_l_t" v-show='showBuyPop'>
            <div class="mask w_h_100 absolute_l_t" @click='showBuyPop=false'></div>
            <div class="wrapper absolute_center flex_col flex_align_center">
                <div class="title">长按保存下方二维码<br>进入微信扫一扫添加公众号</div>
                <img class="qrcode" :src="baseCosBaseUrl+'wx_qr_code.png'" alt="">
                <img class="close_tag clickable" @click='showBuyPop=false' src="@/assets/pc/img/close.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import { baseCosBaseUrl, mobileJDShopHomeUrl, mobileWBUrl } from '@/config/evn.js'
    export default {
        data() {
            return {
                baseCosBaseUrl,
                mobileJDShopHomeUrl,
                showBuyPop: false
            }
        },
    }
</script>

<style lang="scss" scoped>
    .custom_foot_cpe {
        color: #fff;
        background-color: #111111;
        padding: 36px 0 30px;

        .logo {
            width: 95px;
            height: 28px;
        }

        .line_tag {
            width: 238px;
            height: 1px;
            margin: 14px 0 30px;
        }

        .logo_icon {
            width: 119px;
            height: 119px;
        }

        .doit_text {
            line-height: 48px;
            font-family: HurmeGeometricSans3-Bold;
            font-size: 26px;
            color: #0000FF;
            text-align: center;
            letter-spacing: 0.5px;
            margin: 25px 0 40px;
        }

        .third_party {
            display: flex;
            width: 240px;
            height: 70px;
            border-bottom: 1px solid #fff;

            img {
                width: 48px;
                height: 48px;
            }
        }

        .follow_wrap {
            width: 240px;
            margin: 10px 0 50px;

            .tag_2 {
                width: 79px;
                height: 16px;
            }

            span {
                line-height: 29px;
                font-family: HurmeGeometricSans3-SemiBold;
                font-size: 24px;
            }
        }

        .company_name,
        .company_record,
        .gongan {
            font-family: PingFang-Medium;
            letter-spacing: 1px;
        }

        .company_name {
            line-height: 28px;
            font-size: 20px;
            color: #EBEBEB;
        }

        .company_record,
        .gongan {
            line-height: 20px;
            font-size: 16px;
            color: #999999;
            text-align: center;
            margin-top: 12px;

            a {
                color: #999999;
            }
        }

        .gongan {
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }

        .buyPop {
            z-index: 10;

            .mask {
                background-color: rgba(102, 102, 102, 0.3);
            }

            .wrapper {
                width: 460px;
                height: 460px;
                background-color: #fff;
                padding-top: 44px;

                .title {
                    line-height: 37px;
                    font-size: 24px;
                    color: #111111;
                    text-align: center;
                }

                .qrcode {
                    width: 278px;
                    height: 278px;
                    margin-top: 28px;
                }

                .close_tag {
                    position: absolute;
                    right: 30px;
                    top: 30px;
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }
</style>