<template>
    <div class="custom_foot_cpe flex_col flex_align_start">
        <img class="logo" src="@/assets/pc/img/logo_en.png" alt="">
        <div class="line"></div>
        <div class="nav_wrap flex_col flex_align_start">
            <div class="nav_item clickable" v-for="(item, index) of navList" :key="index"
                v-if="item.pageName != curPageName" @click="$gotoPage(item.pageName)">
                <span class="en">{{item.en}}</span>
                <span class="cn">{{item.cn}}</span>
            </div>
        </div>
        <div class="company_name">深圳市乐度健康科技有限公司</div>
        <!-- <div class="company_record">Copyright © 2021.乐度网络 All rights reserved. <a href="https://beian.miit.gov.cn/">粤ICP备20066305号</a>
        </div> -->
        <div class="company_record">Copyright © 2021.乐度健康 All rights reserved.<a class="clickable"
                href="https://beian.miit.gov.cn/"><span>粤ICP备</span>2021165969号-1</a>
        </div>
        <a class="gongan flex_align_center"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030902003153">
            <img src="@/assets/gongan.png" alt="">
            <span>粤公网安备 44030902003153号</span>
        </a>
        <div class="QR_code_wrap flex_col flex_align_center">
            <img :src="baseCosBaseUrl+'wx_qr_code.png'" alt="">
            <span>扫一扫关注我们</span>
        </div>
        <div class="third_party flex_align_center">
            <a class="clickable" @click="$windowOpen(pcJDShopHomeUrl)">
                <img src="@/assets/JD.png" alt="">
            </a>
            <!-- <a href="https://weibo.com/u/7402562420">
                <img src="@/assets/WX.png" alt="">
            </a> -->
            <a class="clickable" @click="$windowOpen(pcWBUrl)">
                <img src="@/assets/WB.png" alt="">
            </a>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { baseCosBaseUrl, pcCosBaseUrl, pcJDShopHomeUrl, pcWBUrl } from '@/config/evn.js'
    export default {
        data() {
            return {
                baseCosBaseUrl,
                pcCosBaseUrl,
                pcJDShopHomeUrl,
                pcWBUrl,
                navList: [
                    {
                        pageName: 'home',
                        en: 'Home',
                        cn: '首页'
                    }, {
                        pageName: 'periphery',
                        en: 'Periphery',
                        cn: '周边'
                    }, {
                        pageName: 'news',
                        en: 'Topical News',
                        cn: '时事新闻'
                    }, {
                        pageName: 'newProducts',
                        en: 'New Products',
                        cn: '新品'
                    }, {
                        pageName: 'about',
                        en: 'About GETO',
                        cn: '关于解套'
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'curPageName'
            ])
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/style/mixin.scss";

    .custom_foot_cpe {
        position: relative;
        color: #fff;
        background-color: #111111;
        padding: formatPX_1920(60px) formatPX_1920(360px) formatPX_1920(80px);

        .logo {
            width: formatPX_1920(156px);
            height: formatPX_1920(48px);
        }

        .line {
            width: 100%;
            height: formatPX_1920(1px);
            background-color: #fff;
            margin: formatPX_1920(30px) 0 formatPX_1920(60px);
        }

        .nav_wrap {
            .nav_item {
                position: relative;
                font-family: HurmeGeometricSans3-Bold;
                font-size: formatPX_1920(38px);
                line-height: formatPX_1920(46px);
                margin-top: formatPX_1920(30px);

                &:hover {
                    color: #0000FF;

                    .cn {
                        display: block;
                    }
                }

                &:first-child {
                    margin-top: 0;
                }

                .cn {
                    position: absolute;
                    left: 100%;
                    bottom: formatPX_1920(6px);
                    display: none;
                    line-height: 1;
                    font-family: SourceHanSansCN-Medium;
                    font-size: formatPX_1920(18px);
                    color: #fff;
                    white-space: nowrap;
                    border-bottom: formatPX_1920(1px) solid #fff;
                    transform: translate3d(formatPX_1920(15px), 0, 0);
                    padding: 0 formatPX_1920(8px) formatPX_1920(5px);
                }
            }
        }

        .company_name,
        .company_record,
        .gongan {
            font-family: PingFang-Medium;
            font-size: formatPX_1920(16px);
        }

        .company_name {
            margin: formatPX_1920(56px) 0 formatPX_1920(20px);
        }

        .company_record {
            a {
                color: #fff;
                margin-left: formatPX_1920(15px);
            }
        }

        .gongan {
            color: #fff;
            margin-top: formatPX_1920(20px);
            img {
                width: formatPX_1920(24px);
                height: formatPX_1920(24px);
                margin-right: formatPX_1920(6px);
            }
        }

        .QR_code_wrap {
            position: absolute;
            right: formatPX_1920(374px);
            top: formatPX_1920(200px);
            width: formatPX_1920(152px);
            font-family: SourceHanSansCN-Medium;
            font-size: formatPX_1920(18px);

            img {
                width: formatPX_1920(152px);
                height: formatPX_1920(152px);
                margin-bottom: formatPX_1920(24px);
            }
        }

        .third_party {
            position: absolute;
            right: formatPX_1920(374px);
            bottom: formatPX_1920(90px);

            a {
                margin-right: formatPX_1920(40px);

                &:last-child {
                    margin-right: 0;
                }
            }

            img {
                width: formatPX_1920(44px);
                height: formatPX_1920(44px);
            }
        }
    }
</style>