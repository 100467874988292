<template>
    <div class="flow_circle_cpe">
        <img ref="tempImg" class="temp_img" src="@/assets/pc/img/flow_bg.png" alt="" @load="tempImgOnload" style="display: none;">
        <canvas class="temp_canvas" :width="canvasW" :height="canvasH" style="display: none;">
            您当前浏览器不支持canvas
        </canvas>
        <canvas class="target_canvas" :width="canvasW" :height="canvasH">
            当前浏览器不支持 canvas
        </canvas>
        <img class="absolute_center" src="@/assets/pc/img/flow_circle_tag.gif" alt="">
    </div>
</template>

<script>

var removeAll, w, pattern
export default {
    data() {
        return {
            // canvasW: 720 * (window.innerWidth || document.body.clientWidth) / 1920,
            // canvasH: 720 * (window.innerWidth || document.body.clientWidth) / 1920,
            canvasW: (window.innerHeight || document.body.clientHeight)*0.9,
            canvasH: (window.innerHeight || document.body.clientHeight)*0.9,
            tempImg: null,
            tempCanvas: null,
            tempContext: null,
            targetCanvas: null,
            targetContext: null
        }
    },
    // created() {
    //     this.canvasH = window.innerHeight || document.documentElement.clientHeight
    //     this.canvasW = window.innerWidth || document.documentElement.clientWidth
    // },
    mounted() {
        var targetCanvas = document.querySelector(".target_canvas")
        this.targetCanvas = targetCanvas
        this.targetContext = targetCanvas.getContext("2d")
        function y(t, e) {
            if (!(t instanceof e))
                throw new TypeError("Cannot call a class as a function")
        }
        function r(t, e) {
            for (var n = 0; n < e.length; n++) {
                var r = e[n];
                r.enumerable = r.enumerable || !1,
                r.configurable = !0,
                "value"in r && (r.writable = !0),
                Object.defineProperty(t, r.key, r)
            }
        }
        function b(t, e, n) {
            return e && r(t.prototype, e),
            n && r(t, n),
            t
        }
        var x = 0, S = 0, o = function() {
            x = targetCanvas.getBoundingClientRect().width,
            S = targetCanvas.getBoundingClientRect().height
        }, E = function() {
            targetCanvas.width = targetCanvas.offsetWidth * 1,
            targetCanvas.height = targetCanvas.offsetHeight * 1,
            targetCanvas.getContext("2d").scale(1, 1)
        }, i = function() {
            window.removeEventListener("resize", o),
            window.removeEventListener("resize", E),
            targetCanvas && (o(),
            window.addEventListener("resize", o),
            function() {
                var o, e, r = window.requestAnimationFrame, p = o, n = targetCanvas, t = (1,
                n.offsetHeight), i = n.offsetWidth, a = Math.min(t, i), s = Math.max(t, i);
                e = i < t ? (s - a) / 2 : 0;
                var h = 3 * (o = a / 10)
                , c = [[5, 9.995], [8.214, 8.825], [9.924, 5.863], [9.33, 2.495], [6.71, .297], [3.29, .297], [.67, 2.495], [.076, 5.863], [1.786, 8.825]].map(function(t) {
                    return [t[0] * o + 0, t[1] * o + e]
                })
                , u = function() {
                    function n(t, e) {
                        y(this, n),
                        this.x = t,
                        this.y = e
                    }
                    return b(n, [{
                        key: "set",
                        value: function(t, e) {
                            return this.x = t,
                            this.y = e,
                            this
                        }
                    }, {
                        key: "copy",
                        value: function(t) {
                            return this.set(t.x, t.y)
                        }
                    }, {
                        key: "translate",
                        value: function(t, e) {
                            return this.set(this.x + t, this.y + e)
                        }
                    }, {
                        key: "scale",
                        value: function(t) {
                            return this.set(this.x * t, this.y * t)
                        }
                    }, {
                        key: "distance",
                        value: function(t) {
                            var e = this.x - t.x
                            , n = this.y - t.y;
                            return Math.sqrt(e * e + n * n)
                        }
                    }]),
                    n
                }()
                , f = function() {
                    function n(t, e) {
                        y(this, n),
                        this.pos = new u(t,e),
                        this.last = new u(t,e),
                        this.anchor = new u(t,e),
                        this.vel = new u(0,0),
                        this.neighbors = []
                    }
                    return b(n, [{
                        key: "addAcrossNeighbor",
                        value: function(t) {
                            this.addNeighbor(t, 1, 2)
                        }
                    }, {
                        key: "addNeighbor",
                        value: function(t, e, n) {
                            this.neighbors.push({
                                pos: t.pos,
                                vel: t.vel,
                                dist: this.pos.distance(t.pos),
                                compress: e,
                                strength: n
                            })
                        }
                    }, {
                        key: "setNeighbors",
                        value: function(t) {
                            this.addNeighbor(t, 30, .5)
                        }
                    }, {
                        key: "move",
                        value: function(t, e) {
                            if (e.inside) {
                                var n = e.pos.x - this.pos.x
                                , r = e.pos.y - this.pos.y;
                                Math.sqrt(n * n + r * r) < h && (this.vel.x -= 6 * n,
                                this.vel.y -= 6 * r)
                            }
                            if (this.pos.x <= 12 && (this.vel.x += 50 * (12 - this.pos.x)),
                            this.pos.y <= 12 && (this.vel.y += 50 * (12 - this.pos.y)),
                            this.pos.x >= x - 12 && (this.vel.x -= 50 * (this.pos.x - (x - 12))),
                            this.pos.y >= S - 12 && (this.vel.y -= 50 * (this.pos.y - (S - 12))),
                            !e.dragging) {
                                if (this.anchor.x <= 12)
                                    for (var o = 0; o < w.island.points.length; ++o)
                                        w.island.points[o].anchor.translate(5, 0);
                                if (this.anchor.x <= 12)
                                    for (var i = 0; i < w.island.points.length; ++i)
                                        w.island.points[i].anchor.translate(5, 0);
                                if (this.anchor.y <= 12)
                                    for (var a = 0; a < w.island.points.length; ++a)
                                        w.island.points[a].anchor.translate(0, 5);
                                if (this.anchor.x >= x + 12)
                                    for (var s = 0; s < w.island.points.length; ++s)
                                        w.island.points[s].anchor.translate(-5, 0);
                                if (this.anchor.y >= S + 12)
                                    for (var c = 0; c < w.island.points.length; ++c)
                                        w.island.points[c].anchor.translate(0, -5)
                            }
                            this.vel.scale(.35);
                            var u = .75 * (this.anchor.x - this.pos.x)
                            , l = .75 * (this.anchor.y - this.pos.y);
                            this.vel.translate(u, l);
                            var p = t * t * .5
                            , f = this.pos.x + .9 * (this.pos.x - this.last.x) + this.vel.x * p
                            , d = this.pos.y + .9 * (this.pos.y - this.last.y) + this.vel.y * p;
                            this.last.copy(this.pos),
                            this.pos.set(f, d)
                        }
                    }, {
                        key: "think",
                        value: function() {
                            for (var t = 0, e = this.neighbors.length; t < e; t++) {
                                var n = this.neighbors[t]
                                , r = this.pos.x - n.pos.x
                                , o = this.pos.y - n.pos.y
                                , i = Math.sqrt(r * r + o * o)
                                , a = (n.dist - i) / i * n.strength;
                                i < n.dist && (a /= n.compress);
                                var s = r * a
                                , c = o * a;
                                this.vel.translate(+s, +c),
                                n.vel.translate(-s, -c)
                            }
                        }
                    }]),
                    n
                }()
                , l = function() {
                    function l(t) {
                        y(this, l),
                        this.points = [];
                        for (var e = 0, n = t.length; e < n; e++)
                            this.points.push(new f(t[e][0],t[e][1]));
                        for (var r = 0, o = this.points.length; r < o; r++) {
                            var i = this.points[r]
                            , a = 0 === r ? o - 1 : r - 1
                            , s = r === o - 1 ? 0 : r + 1;
                            i.setNeighbors(this.points[a], this.points[s]);
                            for (var c = 0; c < o; c++) {
                                var u = this.points[c];
                                u !== i && u !== this.points[a] && u !== this.points[s] && i.pos.distance(u.pos) <= p && i.addAcrossNeighbor(u)
                            }
                        }
                    }
                    return b(l, [{
                        key: "update",
                        value: function(t) {
                            var e, n = this.points.length;
                            for (e = 0; e < n; e++)
                                this.points[e].think();
                            for (e = 0; e < n; e++)
                                this.points[e].move(.06, t)
                        }
                    }, {
                        key: "wobble",
                        value: function(t) {
                            for (var e = 0; e < this.points.length; ++e) {
                                var n = t * (Math.random() - .5) * o
                                , r = t * (Math.random() - .5) * o;
                                this.points[e].pos.translate(n, r)
                            }
                        }
                    }]),
                    l
                }()
                , d = function() {
                    function t() {
                        y(this, t),
                        this.drawAnchors = 0,
                        this.drawJellies = 0,
                        this.drawMouse = 0,
                        this.drawOutline = 0,
                        this.drawCurvy = !0
                    }
                    return b(t, [{
                        key: "shouldDrawAnchors",
                        value: function() {
                            return this.drawAnchors
                        }
                    }, {
                        key: "shouldDrawJellies",
                        value: function() {
                            return this.drawJellies
                        }
                    }, {
                        key: "shouldDrawMouse",
                        value: function() {
                            return this.drawMouse
                        }
                    }, {
                        key: "shouldDrawOutline",
                        value: function() {
                            return this.drawOutline
                        }
                    }, {
                        key: "shouldDrawCurvy",
                        value: function() {
                            return this.drawCurvy
                        }
                    }]),
                    t
                }()
                , m = function() {
                    function e(t) {
                        y(this, e),
                        this.dImg = this.cacheDotImg("rgb(230, 90, 70)"),
                        this.aImg = this.cacheDotImg("rgba(152, 65, 52, 0.5)"),
                        this.canvas = t,
                        this.ctx = this.canvas.getContext("2d"),
                        this.ctx.lineWidth = 4,
                        this.ctx.lineCap = "round",
                        this.ctx.lineJoin = "round",
                        this.opts = new d,
                        window.addEventListener("resize", E),
                        E()
                    }
                    return b(e, [{
                        key: "cacheDotImg",
                        value: function(t) {
                            var e = document.createElement("canvas");
                            e.width = 22,
                            e.height = 22;
                            var n = e.getContext("2d");
                            return n.lineWidth = 3,
                            n.lineCap = "round",
                            n.lineJoin = "round",
                            n.strokeStyle = t,
                            n.beginPath(),
                            n.arc(11, 11, 6, 0, 2 * Math.PI, !0),
                            n.stroke(),
                            e
                        }
                    }, {
                        key: "clear",
                        value: function() {
                            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
                        }
                    }, {
                        key: "drawDots",
                        value: function(t, e, n) {
                            for (var r = 0, o = t.length; r < o; r++)
                                this.ctx.drawImage(n, t[r][e].x - n.width / 2, t[r][e].y - n.height / 2, n.width, n.height)
                        }
                    }, {
                        key: "curveBetween",
                        value: function(t, e) {
                            this.ctx.quadraticCurveTo(t.x, t.y, .5 * (t.x + e.x), .5 * (t.y + e.y))
                        }
                    }, {
                        key: "outlineCurvePath",
                        value: function(t) {
                            this.ctx.beginPath(),
                            this.ctx.moveTo(t[0].pos.x, t[0].pos.y);
                            for (var e = 0, n = t.length; e <= n; ++e) {
                                var r = t[n <= e + 0 ? e + 0 - n : e + 0].pos
                                , o = t[n <= e + 1 ? e + 1 - n : e + 1].pos;
                                this.ctx.quadraticCurveTo(r.x, r.y, .5 * (r.x + o.x), .5 * (r.y + o.y))
                            }
                        }
                    }, {
                        key: "outlineSolidPath",
                        value: function(t) {
                            this.ctx.beginPath(),
                            this.ctx.moveTo(t[0].pos.x, t[0].pos.y);
                            for (var e = 1, n = t.length; e < n; ++e)
                                this.ctx.lineTo(t[e].pos.x, t[e].pos.y);
                            this.ctx.closePath()
                        }
                    }, {
                        key: "drawIsland",
                        value: function(t) {
                            var e = t.points;
                            // this.ctx.fillStyle = "#0000FF";
                            // this.ctx.drawImage(document.querySelector(".temp_canvas"), 0, 0)
                            // var pattern = this.ctx.createPattern(document.querySelector(".temp_canvas"), "repeat");
                            this.ctx.fillStyle = pattern;
                            var n = this.opts.drawCurvy;
                            n && (this.outlineCurvePath(e),
                            this.ctx.fill());
                            var r = this.opts.shouldDrawOutline();
                            !r && n || (r && (this.ctx.strokeStyle = "rgba(243, 218, 131, 0.5)"),
                            this.outlineSolidPath(e),
                            r && this.ctx.stroke(),
                            n || this.ctx.fill()),
                            this.opts.shouldDrawAnchors() && this.drawDots(e, "anchor", this.aImg),
                            this.opts.shouldDrawJellies() && this.drawDots(e, "pos", this.dImg)
                        }
                    }, {
                        key: "drawMouse",
                        value: function(t) {
                            t.dragging ? (this.ctx.fillStyle = "rgba(255, 0, 255, 0.46)",
                            n.style.cursor = "grabbing") : n.style.cursor = "auto",
                            this.opts.shouldDrawMouse() && (this.ctx.fillStyle = "rgba(141, 46, 86, 0.5)",
                            this.ctx.strokeStyle = "rgba(170, 60, 82, 0.5)",
                            this.ctx.beginPath(),
                            this.ctx.arc(t.pos.x, t.pos.y, h, 0, 2 * Math.PI, !0),
                            this.ctx.stroke(),
                            this.ctx.fill())
                        }
                    }]),
                    e
                }()
                , v = function t(r, o) {
                    y(this, t),
                    this.pos = new u(0,0),
                    this.down = !1,
                    this.inside = !1,
                    this.dragging = !1,
                    this.dragX = 0,
                    this.dragY = 0;
                    var i = this;
                    r.onmousemove = function(t) {
                        i.inside = !0;
                        var e = r.getBoundingClientRect();
                        if (i.pos.set(t.clientX - e.left, t.clientY - e.top),
                        i.dragging) {
                            i.dragX = i.pos.x - i.mouseStartX,
                            i.dragY = i.pos.y - i.mouseStartY,
                            i.mouseStartX = i.pos.x,
                            i.mouseStartY = i.pos.y;
                            for (var n = 0; n < o.points.length; ++n)
                                o.points[n].anchor.translate(i.dragX, i.dragY)
                        }
                        return t.preventDefault()
                    }
                    ,
                    r.onmouseup = function(t) {
                        return i.down = !1,
                        i.dragging = !1,
                        w.island.wobble(.1),
                        t.preventDefault()
                    }
                    ,
                    r.onmousedown = function(t) {
                        i.down = !0,
                        0 == i.dragging && (i.mouseStartX = i.pos.x,
                        i.mouseStartY = i.pos.y,
                        i.dragging = !0,
                        w.island.wobble(.1));
                        var e = r.getBoundingClientRect();
                        return i.pos.set(t.clientX - e.left, t.clientY - e.top),
                        t.preventDefault()
                    }
                    ,
                    r.onmouseleave = function() {
                        i.inside = !1,
                        i.dragging = !1,
                        i.pos.set(-999, -999)
                    }
                    ,
                    r.ontouchstart = function() {
                        w.island.wobble(.2)
                    }
                    ,
                    r.ontouchend = function() {
                        w.island.wobble(.2)
                    }
                };
                (w = new (function() {
                    function n(t, e) {
                        y(this, n),
                        this.canvas = t,
                        this.screen = new m(this.canvas),
                        this.island = new l(e),
                        this.mouse = new v(this.canvas,this.island),
                        this.tick = this.tick.bind(this)
                    }
                    return b(n, [{
                        key: "updatePositions",
                        value: function() {
                            this.island.update(this.mouse)
                        }
                    }, {
                        key: "render",
                        value: function() {
                            this.screen.clear(),
                            this.screen.drawIsland(this.island),
                            this.screen.drawMouse(this.mouse)
                        }
                    }, {
                        key: "stop",
                        value: function() {
                            this.running = !1
                        }
                    }, {
                        key: "start",
                        value: function() {
                            this.lastTick = (new Date).getTime(),
                            this.lastPrint = (new Date).getTime(),
                            this.running = !0,
                            this.island.wobble(.1),
                            this.tick()
                        }
                    }, {
                        key: "tick",
                        value: function() {
                            if (this.running) {
                                r(this.tick);
                                for (var t = (new Date).getTime(), e = .06 * (t - this.lastTick); 0 <= e--; )
                                    this.updatePositions();
                                this.lastTick = t,
                                this.render(),
                                250 < t - this.lastPrint && (this.lastPrint = t)
                            }
                        }
                    }]),
                    n
                }())(n,c)).start()
            }())
        }
        removeAll = function() {
            window.removeEventListener("resize", o)
            window.removeEventListener("resize", E)
        }
        i()

        this.tempImg = document.querySelector(".temp_img")
        this.tempCanvas = document.querySelector(".temp_canvas")
        this.tempContext = this.tempCanvas.getContext('2d')
    },
    destroyed() {
        // 移除并停止flowCircle相关监听
        removeAll()
        w.stop()
    },
    methods: {
        tempImgOnload() {
            // this.tempContext.drawImage(this.tempImg, 0, 0, this.canvasW, this.canvasH)
            this.tempContext.drawImage(this.tempImg, 0, 0, this.tempImg.width, this.tempImg.height, 0, 0, this.tempCanvas.width, this.tempCanvas.height)
            // var pattern = this.targetContext.createPattern(this.tempCanvas, "repeat");
            // this.targetContext.fillStyle = pattern;
            pattern = this.targetContext.createPattern(document.querySelector(".temp_canvas"), "repeat");
        }
    },
}
</script>

<style lang="scss" scoped>
.flow_circle_cpe {
    position: relative;
    .target_canvas {
        cursor: url('~@/assets/pc/img/cursor_1.png'), auto !important;
    }
    canvas {
        display: block;
    }
    img {
        width: 100PX;
        height: 100PX;
        pointer-events: none;
    }
}
</style>
