import store from '../store'
import router from '../router'

// 自定义操作LocalStorage方法
let handleLocalStorage = function (method, key, value) {
    switch (method) {
        case 'get': {
            let temp = window.localStorage.getItem(key);
            if (temp) {
                return temp
            } else {
                return false
            }
        }
        case 'set': {
            window.localStorage.setItem(key, value);
            break
        }
        case 'remove': {
            window.localStorage.removeItem(key);
            break
        }
        default: {
            return false
        }
    }
}
// 自定义一个跳转页面的方法
function gotoPage(pagename, params) {

    if (store.state.curPageName == pagename) {
        window.scrollTo(0, 0)
    } else {
        // if (pagename == 'm-home') {
        //     pagename = '/'
        //     window.scrollTo(0, 0)
        // }
        router.push({
            path: `/${pagename}`,
            query: params ? params : {}
        })
    }
}

/**
 *
 * @param {*} url
 * @param {*} name 窗口名称
    // _blank - URL加载到一个新的窗口。这是默认
    // _parent - URL加载到父框架
    // _self - URL替换当前页面
    // _top - URL替换任何可加载的框架集
 */
function windowOpen(url, name='_blank') {
    window.open(url,name)
}
// 跳转微信小程序
function openWXWApplet(url) {
    location.href = url
}
export default {
    handleLocalStorage,
    gotoPage,
    windowOpen,
    openWXWApplet
}