import mCustomHead from './m-customHead.vue'
import mCustomFoot from './m-customFoot.vue'

const commonComponents = {
    install: function(Vue) {
        Vue.component('mCustomHead', mCustomHead)
        Vue.component('mCustomFoot', mCustomFoot)
    }
}

export default commonComponents