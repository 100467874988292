import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index.vue';
import Home from '@/views/pc/Home.vue'
import MHome from '@/views/mobile/m-Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: {
            title:"解套官网",
            content:{
              keywords:"GETO, GETO 解套, 解套官网",
              description:"欢迎进入解套官网，了解更多解套相关。。。",
            }
        }
    },
    // pc端首页
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            title:"解套官网",
            content:{
              keywords:"GETO, GETO 解套, 解套官网",
              description:"欢迎进入解套官网，了解更多解套相关。。。",
            }
        }
    },
    // mobile端首页
    {
        path: '/m-home',
        name: 'm-home',
        component: MHome,
        meta:{
            title:"解套官网",
            content:{
              keywords:"GETO, GETO 解套, 解套官网",
              description:"欢迎进入解套官网，了解更多解套相关。。。",
            }
        }
    },
    // pc端页面
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/pc/About.vue'),
        meta:{
            title:"解套说",
            content:{
              keywords:"解套说",
              description:"欢迎进入解套说，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/periphery',
        name: 'periphery',
        component: () => import('@/views/pc/PeripheryOrNewProducts.vue'),
        meta:{
            title:"解套周边",
            content:{
              keywords:"解套周边",
              description:"欢迎进入解套周边，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/newProducts',
        name: 'newProducts',
        component: () => import('@/views/pc/PeripheryOrNewProducts.vue'),
        meta:{
            title:"解套新品",
            content:{
              keywords:"解套新品",
              description:"欢迎进入解套新品，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/news',
        name: 'news',
        component: () => import('@/views/pc/News.vue'),
        meta:{
            title:"解套新闻",
            content:{
              keywords:"解套新闻",
              description:"欢迎进入解套新闻，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/product',
        name: 'product',
        component: () => import('@/views/pc/Product.vue'),
        meta:{
            title:"解套产品",
            content:{
              keywords:"解套产品",
              description:"欢迎进入解套产品，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/pc/Detail.vue'),
        meta:{
            title:"解套详情",
            content:{
              keywords:"解套详情",
              description:"欢迎进入解套详情，了解更多解套相关。。。",
            }
        }
    },
    // mobile端页面
    {
        path: '/m-about',
        name: 'm-about',
        component: () => import('@/views/mobile/m-About.vue'),
        meta:{
            title:"解套说",
            content:{
              keywords:"解套说",
              description:"欢迎进入解套说，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/m-periphery',
        name: 'm-periphery',
        component: () => import('@/views/mobile/m-peripheryOrNewProducts.vue'),
        meta:{
            title:"解套周边",
            content:{
              keywords:"解套周边",
              description:"欢迎进入解套周边，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/m-newProducts',
        name: 'm-newProducts',
        component: () => import('@/views/mobile/m-peripheryOrNewProducts.vue'),
        meta:{
            title:"解套新品",
            content:{
              keywords:"解套新品",
              description:"欢迎进入解套新品，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/m-news',
        name: 'm-news',
        component: () => import('@/views/mobile/m-News.vue'),
        meta:{
            title:"解套新闻",
            content:{
              keywords:"解套新闻",
              description:"欢迎进入解套新闻，了解更多解套相关。。。",
            }
        }
    }, {
        path: '/m-product',
        name: 'm-product',
        component: () => import('@/views/mobile/m-Product.vue'),
        meta:{
            title:"解套产品",
            content:{
              keywords:"解套产品",
              description:"欢迎进入解套产品，了解更多解套相关。。。",
            }
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
})

export default router