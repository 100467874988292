<template>
    <div class="home_page">
        <m-custom-head></m-custom-head>
        <div class="banner_wrap">
            <swiper ref="bannerSwiper" :options="bannerSwiperOptions" class="banner_swiper w_h_100 swiper-no-swiping"
                @slideChangeTransitionStart="bannerChangeTransitionStart">
                <swiper-slide v-for="(item,index) of bannerList" :key="index">
                    <div class="banner_item w_h_100" :style="{backgroundImage: 'url('+item.img+')'}"></div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <h1>DO IT FOR CHANGE</h1>
            <div class="banner_text_wrap flex_align_start">
                <img :src="bannerList[bannerActiveIndex].bannerText" alt="" class="banner_text">
                <img src="@/assets/pc/img/next.png" alt="" class="next_btn clickable" @click="handleNextBtnFromBanner">
            </div>
        </div>
        <div class="unlock_wrap">
            <div class="title_wrap flex_align_end flex_justify_between">
                <img src="@/assets/mobile/img/unlock_title.png" alt="" class="title_tag">
                <img src="@/assets/mobile/img/tag_8.png" alt="" class="tag">
            </div>
            <div class="content">
                解套GETO致力于打造好玩有趣、年轻时尚的两性情趣生活品牌，我们在甜酷女孩的生活视角中汲取灵感，倡导用积极乐观、激情洒脱的生活态度去面对现实中的困惑，相信一切都能迎刃而解。
            </div>
            <div class="more_btn flex_align_center flex_justify_between" @click="$gotoPage('m-about')">
                <span>more</span>
                <img src="@/assets/mobile/img/tag_1.png" alt="">
            </div>
        </div>
        <div class="video_wrap">
            <img src="@/assets/mobile/img/video_title.png" class="title_tag" alt="">
            <video controls :src="baseCosBaseUrl+'home_video.mp4'" :poster="baseCosBaseUrl+'home_video_poster.png'" playsinline webkit-playsinline></video>
            <img src="@/assets/pc/img/rotate_tag.png" alt="" class="rotate_tag rotate">
        </div>
        <div class="product_wrap" ref="productWrap">
            <img src="@/assets/mobile/img/product_title.png" class="title_tag" alt="">
            <div class="product_swiper_container">
                <swiper ref="productSwiper" :options="productSwiperOptions" class="product_swiper w_h_100"
                    @slideChangeTransitionStart="productChangeTransitionStart">
                    <swiper-slide class="product_swiper_slide w_h_100" v-for="(item, index) of productList"
                        :key="index">
                        <div class="product_item w_h_100 flex_justify_between" @click="$gotoPage('m-product', {id: item.id})">
                            <img v-lazy="item.imgs[0]" alt="" class="product_img_1">
                            <div class="product_info flex_col">
                                <div class="cn">
                                    <span>{{item.cn}}</span>
                                    <span>0{{index+1}}</span>
                                </div>
                                <div class="en">{{item.en}}</div>
                                <img v-lazy="item.imgs[1]" class="product_img_2" alt="">
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper_pagination">
                <div ref="paginationItem" class="pagination_item flex_center_center"
                    :class="productActiveIndex == index? 'actived': ''" v-for="(item, index) of productList"
                    :key="index" @click="productSwiper.slideTo(index)">
                    0{{index+1}}
                </div>
                <div class="actived_line" :style="{left: paginationItemWidth*productActiveIndex+'px'}"></div>
            </div>
            <img src="@/assets/mobile/img/tag_2.png" alt="" class="tip" :class="isShowTipTag?'actived':''">
        </div>
        <div class="peripheral_wrap">
            <img src="@/assets/mobile/img/peripheral_title.png" class="title_tag" alt="">
            <div class="peripheral_swiper_container flex_align_center">
                <img src="@/assets/mobile/img/rotate_tag.png" class="rotate_tag rotate" alt="">
                <div class="peripheral_swiper_wrap">
                    <swiper ref="peripheralSwiper" :options="peripheralSwiperOptions" class="peripheral_swiper w_h_100">
                        <swiper-slide class="peripheral_swiper_slide w_h_100" v-for="(item, index) of peripheralList"
                            :key="index">
                            <div class="peripheral_item w_h_100" @click="$gotoPage('m-product', {id: item.id})">
                                <img v-lazy="item.img" class="w_h_100" alt="">
                            </div>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
        </div>
        <div class="series_wrap" :style="{backgroundImage: 'url('+mobileCosBaseUrl+'home_series_img.png)'}">
            <img src="@/assets/mobile/img/more_btn.png" class="more_btn" @click="$windowOpen(mobileJDShopHomeUrl)" alt="">
        </div>
        <div class="news_wrap">
            <div class="title_wrap">
                <h1 class="en">TOPICAL NEWS</h1>
                <h2 class="cn">时事新闻</h2>
            </div>
            <div class="news_content">
                <div
                    class="news_item flex_align_center"
                    v-for="(item, index) of newsData"
                    :key="index"
                    @click="$windowOpen(item.address)">
                    <img v-lazy="item.coverImg" class="news_img" alt="">
                    <div class="news_info">
                        <div class="time">{{item.time}}</div>
                        <div class="title">{{item.title}}</div>
                        <img src="@/assets/mobile/img/tag_3.png" class="tag" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="hot_wrap">
            <div class="title_wrap">
                <h1 class="en">MORE PRODUCTS</h1>
                <h2 class="cn">热门推荐</h2>
            </div>
            <div class="hot_swiper_container">
                <swiper
                    ref="hotSwiper"
                    :options="hotSwiperOptions"
                    class="hot_swiper w_h_100"
                    @slideChangeTransitionStart="hotChangeTransitionStart">
                    <swiper-slide class="hot_swiper_slide w_h_100 flex_center_center" v-for="(item, index) of hotList"
                        :key="index">
                        <img class="hot_img" :src="item.img" :data-id="item.id" alt="">
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper_pagination flex_align_center flex_justify_between">
                <img src="@/assets/mobile/img/prev_btn.png" class="btn" @click="hotSwiper.slidePrev()" alt="">
                <div class="pagination_wrap">
                    <span class="pagination_item" :class="hotActiveIndex == index ? 'actived': ''" v-for="(item, index) of hotList" :key="index"></span>
                </div>
                <img src="@/assets/mobile/img/next_btn.png" class="btn"  @click="hotSwiper.slideNext()" alt="">
            </div>
        </div>
        <m-custom-foot></m-custom-foot>
    </div>
</template>

<script>
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'

    import { baseCosBaseUrl, mobileCosBaseUrl, pcCosBaseUrl, mobileJDShopHomeUrl } from '@/config/evn.js'
    import { mapState } from 'vuex'

    let fullWidth = document.documentElement.clientWidth
    let paginationItemWidth = 138 * fullWidth / 750
    let vm
    export default {
        name: 'm-home',
        data() {
            return {
                baseCosBaseUrl,
                mobileCosBaseUrl,
                mobileJDShopHomeUrl,
                isShowTipTag: false,
                clientHeight: window.innerHeight || document.documentElement.clientHeight, // 浏览器可见区域高度
                clientWidth: window.innerWidth || document.documentElement.clientWidth, // 浏览器可见区域宽度
                bannerActiveIndex: 0, // banner当前激活的index
                productActiveIndex: 0, // product当前激活的index
                hotActiveIndex: 0, // hot当前激活的index
                productWrapTop: 0, // productWrap距离顶部的距离
                productWrapHeight: 0, // productWrap本身高度
                paginationItemWidth: paginationItemWidth, // paginationItem的宽度
                bannerSwiperOptions: {
                    effect: 'fade',
                    slidesPerView: '1',
                    autoplay: {
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                },
                productSwiperOptions: {
                    slidesPerView: '1'
                },
                peripheralSwiperOptions: {
                    effect: 'fade',
                    slidesPerView: '1',
                    autoplay: {
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    }
                },
                hotSwiperOptions: {
                    slidesPerView: '3',
                    autoplay: false,
                    centeredSlides: true,
                    loop: true,
                    on: {
                        init: function(swiper){
                        //Swiper完成初始化了
                            vm.hotSwiperInit()
                        },
                    }
                },
                bannerList: [
                    {
                        id: 1,
                        img: mobileCosBaseUrl + 'home_banner_1.jpg',
                        bannerText: pcCosBaseUrl + 'home_banner_1_text.png'
                    }, {
                        id: 2,
                        img: mobileCosBaseUrl + 'home_banner_2.jpg',
                        bannerText: pcCosBaseUrl + 'home_banner_2_text.png'
                    }, {
                        id: 3,
                        img: mobileCosBaseUrl + 'home_banner_3.jpg',
                        bannerText: pcCosBaseUrl + 'home_banner_3_text.png'
                    }
                ],
                productList: [
                    {
                        id: 4,
                        cn: '超润超薄款',
                        en: 'Silver ultra-moist ultra-thin\ncondom of jietao',
                        imgs: [
                            mobileCosBaseUrl + 'home_product_04-1.png',
                            mobileCosBaseUrl + 'home_product_04-2.png'
                        ]
                    }, {
                        id: 5,
                        cn: '持久延时款',
                        en: 'Long-lasting condom of jietao',
                        imgs: [
                            mobileCosBaseUrl + 'home_product_05-1.png',
                            mobileCosBaseUrl + 'home_product_05-2.png'
                        ]
                    }, {
                        id: 3,
                        cn: '玻尿酸款',
                        en: 'Pink hyaluronic acid condom\nof jietao',
                        imgs: [
                            mobileCosBaseUrl + 'home_product_03-1.png',
                            mobileCosBaseUrl + 'home_product_03-2.png'
                        ]
                    }, {
                        id: 2,
                        cn: '超薄款',
                        en: 'Ultra-thin condom of jietao',
                        imgs: [
                            mobileCosBaseUrl + 'home_product_02-1.png',
                            mobileCosBaseUrl + 'home_product_02-2.png'
                        ]
                    }, {
                        id: 1,
                        cn: '螺纹颗粒款',
                        en: 'Green thread pellet condom\nof jietao',
                        imgs: [
                            mobileCosBaseUrl + 'home_product_01-1.png',
                            mobileCosBaseUrl + 'home_product_01-2.png'
                        ]
                    }
                ],
                peripheralList: [
                    {
                        id: 8,
                        img: mobileCosBaseUrl + 'home_peripheral_08.png'
                    }, {
                        id: 10,
                        img: mobileCosBaseUrl + 'home_peripheral_10.png'
                    }, {
                        id: 7,
                        img: mobileCosBaseUrl + 'home_peripheral_07.png'
                    }, {
                        id: 9,
                        img: mobileCosBaseUrl + 'home_peripheral_09.png'
                    }, {
                        id: 6,
                        img: mobileCosBaseUrl + 'home_peripheral_06.png'
                    }
                ],
                hotList: [
                    {
                        id: 9,
                        img: mobileCosBaseUrl + 'home_hot_09.png'
                    }, {
                        id: 8,
                        img: mobileCosBaseUrl + 'home_hot_08.png'
                    }, {
                        id: 6,
                        img: mobileCosBaseUrl + 'home_hot_06.png'
                    }, {
                        id: 10,
                        img: mobileCosBaseUrl + 'home_hot_10.png'
                    }, {
                        id: 7,
                        img: mobileCosBaseUrl + 'home_hot_07.png'
                    }
                ]
            }
        },
        created() {
            vm = this
        },
        mounted() {
            document.addEventListener('scroll', this.handleScroll)
            this.productWrapTop = this.$refs.productWrap.offsetTop
            this.productWrapHeight = this.$refs.productWrap.offsetHeight
        },
        destroyed() {
            document.removeEventListener('scroll', this.handleScroll)
        },
        computed: {
            ...mapState(['newsData', 'platform']),
            bannerSwiper() {
                return this.$refs.bannerSwiper.swiper
            },
            productSwiper() {
                return this.$refs.productSwiper.swiper
            },
            hotSwiper() {
                return this.$refs.hotSwiper.swiper
            }
        },
        methods: {
            handleScroll() {
                let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
                if (scrollTop >= this.productWrapTop - this.clientHeight + this.productWrapHeight) {
                    if (!this.isShowTipTag) this.isShowTipTag = true
                }
            },
            hotSwiperInit() {
                let hotImgs = Array.from(document.getElementsByClassName('hot_img'));
                if(!Array.isArray(hotImgs)) return;
                hotImgs.forEach((item)=>{
                    item.addEventListener('click', (e) => {
                        this.$gotoPage('m-product', {id: +e.currentTarget.dataset.id})
                    })
                })
            },
            bannerChangeTransitionStart() {
                this.bannerActiveIndex = this.bannerSwiper.activeIndex
            },
            productChangeTransitionStart() {
                this.productActiveIndex = this.productSwiper.activeIndex
            },
            hotChangeTransitionStart() {
                this.hotActiveIndex = this.hotSwiper.realIndex
            },
            handleNextBtnFromBanner() {
                if (this.bannerActiveIndex == this.bannerList.length - 1) return this.bannerSwiper.slideTo(0)
                this.bannerSwiper.slideNext()
            },
        },
        components: {
            swiper,
            swiperSlide
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/style/mixin.scss";

    @-webkit-keyframes rotate {
        0% {
            transform: rotate();
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes tipFadeOut {
        0% {
            opacity: 0;
            transform: translateX(52px);
        }

        16.66% {
            opacity: 1;
            transform: translateX(0);
        }

        33.32% {
            opacity: 0;
            transform: translateX(0);
        }

        33.33% {
            transform: translateX(52px);
        }

        49.98% {
            opacity: 1;
            transform: translateX(0);
        }

        66.64% {
            opacity: 0;
            transform: translateX(0);
        }

        66.65% {
            transform: translateX(52px);
        }

        83.30% {
            opacity: 1;
            transform: translateX(0);
        }

        100% {
            opacity: 0;
            transform: translateX(0);
        }
    }

    .rotate {
        animation: rotate 3s infinite linear;
    }

    .home_page {
        text-align: left;

        .banner_wrap {
            position: relative;
            height: 100vh;

            .banner_swiper {
                ::v-deep.swiper-pagination {
                    bottom: 60px;

                    .swiper-pagination-bullet {
                        width: 16px;
                        height: 16px;
                        background-color: transparent;
                        border: 2px solid #fff;
                        opacity: 1;
                        margin: 0 5px;
                    }

                    .swiper-pagination-bullet-active {
                        background-color: #fff;
                    }
                }

                .banner_item {
                    background-size: cover;
                    background-position: center;
                }
            }

            h1,
            .banner_text_wrap {
                position: absolute;
                left: 32px;
                z-index: 1;
            }

            h1 {
                bottom: 270px;
                line-height: 58px;
                font-family: HurmeGeometricSans3-Black;
                font-size: 48px;
                color: #fff;
                letter-spacing: 2px;
            }

            .banner_text_wrap {
                height: 46px;
                bottom: 190px;

                .banner_text {
                    height: 100%;
                }

                .next_btn {
                    width: 49px;
                    height: 100%;
                    margin-left: 20px;
                }
            }
        }

        .unlock_wrap {
            height: 646px;
            color: #fff;
            background-color: #0000FF;
            padding-top: 160px;

            .title_wrap {
                height: 107px;
                padding: 0 32px;

                .title_tag {
                    width: 274px;
                    height: 100%;
                }

                .tag {
                    width: 133px;
                    height: 27px;
                    margin-bottom: 8px;
                }
            }

            .content {
                line-height: 48px;
                font-family: SourceHanSansCN-Medium;
                font-size: 24px;
                padding: 0 40px;
                margin-top: 48px;
            }

            .more_btn {
                width: 154px;
                height: 43px;
                font-family: HurmeGeometricSans3-SemiBold;
                font-size: 26px;
                background-color: #111111;
                padding: 0 24px;
                margin: 33px 32px 0 auto;

                img {
                    width: 33px;
                    height: 19px;
                }
            }
        }

        .video_wrap {
            position: relative;
            background-color: #111111;
            padding: 160px 0 0 30px;

            .title_tag {
                width: 384px;
                height: 112px;
                margin-bottom: 60px;
            }

            video {
                width: 629px;
                height: 354px;
            }

            .rotate_tag {
                position: absolute;
                right: 21px;
                bottom: 296px;
                width: 114px;
                height: 114px;
            }
        }

        .product_wrap {
            position: relative;
            color: #fff;
            background-color: #111111;
            padding: 160px 30px 60px;

            .title_tag {
                width: 359px;
                height: 102px;
            }

            .product_swiper_container {
                width: 100%;
                height: 358px;
                margin: 60px 0 30px;

                .product_item {
                    .product_img_1 {
                        width: 351px;
                        height: 100%;
                    }

                    .product_info {
                        width: 305px;
                        height: 100%;

                        .cn {
                            font-size: 24px;

                            span:last-child {
                                font-family: HurmeGeometricSans3-SemiBold;
                                margin-left: 14px;
                            }
                        }

                        .en {
                            line-height: 22px;
                            font-family: HurmeGeometricSans3-Light;
                            font-size: 16px;
                            white-space: pre-wrap;
                            margin-top: 11px;
                        }

                        .product_img_2 {
                            width: 100%;
                            height: 220px;
                            margin-top: auto;
                        }
                    }
                }
            }

            .swiper_pagination {
                position: relative;
                display: flex;
                height: 46px;
                font-family: HurmeGeometricSans3-SemiBold;
                font-size: 24px;
                color: #3C3C3C;
                border-bottom: 4px solid rgba(255, 255, 255, 0.52);
                border-right: 2px;

                .pagination_item {
                    width: 138px;
                    flex-shrink: 0;
                    transition: all 0.3s ease;

                    &.actived {
                        color: #fff;
                    }
                }

                .actived_line {
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    width: 138px;
                    height: 4px;
                    background-color: #fff;
                    border-right: 2px;
                    transition: all 0.5s ease;
                }
            }

            .tip {
                position: absolute;
                right: 19px;
                bottom: 212px;
                z-index: 1;
                width: 43px;
                height: 38px;
                opacity: 0;
                transform: translateX(52px);

                &.actived {
                    animation: tipFadeOut 3s ease;
                }
            }
        }

        .peripheral_wrap {
            padding: 160px 0;

            .title_tag {
                width: 343px;
                height: 92px;
                margin: 0 0 60px 30px;
            }

            .peripheral_swiper_container {
                height: 302px;

                .rotate_tag {
                    width: 234px;
                    height: 234px;
                }

                .peripheral_swiper_wrap {
                    width: 452px;
                    height: 100%;
                    background-color: pink;

                    .peripheral_swiper {
                        ::v-deep.swiper-pagination {
                            bottom: 14px;

                            .swiper-pagination-bullet {
                                width: 8px;
                                height: 8px;
                                background-color: #CECECE;
                                opacity: 1;
                                margin: 0 3px;
                            }

                            .swiper-pagination-bullet-active {
                                background-color: #FF4400;
                            }
                        }
                    }
                }
            }
        }

        .series_wrap {
            position: relative;
            height: 728px;
            background-size: 100% 100%;

            .more_btn {
                position: absolute;
                left: 106px;
                bottom: 220px;
                width: 105px;
                height: 32px;
            }
        }

        .news_wrap,
        .hot_wrap {
            padding-top: 160px;

            .title_wrap {
                text-align: center;

                h1 {
                    line-height: 46px;
                    font-family: HurmeGeometricSans3-Black;
                    font-size: 38px;
                }

                h2 {
                    font-size: 24px;
                    color: #2C2C2C;
                }
            }
        }

        .news_wrap {
            .news_content {
                width: 658px;
                border-top: 2px solid #111111;
                margin: 60px 0 0 32px;

                .news_item {
                    width: 100%;
                    height: 262px;
                    border-bottom: 2px solid #111111;

                    .news_img {
                        width: 280px;
                        height: 199px;
                    }

                    .news_info {
                        position: relative;
                        width: 100%;
                        height: 199px;
                        border-left: 2PX solid #CECECE;
                        padding-left: 38px;
                        margin-left: 12px;

                        .time {
                            line-height: 24px;
                            font-family: HurmeGeometricSans3-Light;
                            font-size: 18px;
                            color: #999999;
                        }

                        .title {
                            @include no-wrap-multi(3);
                            line-height: 34px;
                            font-size: 24px;
                            margin-top: 35px;
                        }

                        .tag {
                            position: absolute;
                            right: 35px;
                            bottom: 0;
                            width: 32px;
                            height: 42px;
                        }
                    }
                }
            }
        }

        .hot_wrap {
            padding-bottom: 140px;
            .hot_swiper_container {
                height: 299px;
                padding: 0 12px;
                margin: 60px 0 30px;

                .hot_swiper {
                    .hot_swiper_slide {
                        &.swiper-slide-active {
                            .hot_img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .hot_img {
                            width: 202px;
                            height: 250px;
                            transition: all 0.3s ease;
                        }
                    }

                }
            }
            .swiper_pagination {
                height: 48px;
                padding: 0 230px;
                .btn {
                    width: 48px;
                    height: 100%;
                }
                .pagination_wrap {
                    display: flex;
                    .pagination_item {
                        width: 10px;
                        height: 10px;
                        background-color: #CECECE;
                        border-radius: 50%;
                        margin: 0 3px;
                        &.actived {
                            background-color: #FF4400;
                        }
                    }
                }
            }
        }
    }
</style>