<template>
    <!-- <div class="home_page" :class="!isShowedWelcome ? 'no_height' : ''" ref="home"> -->
    <div class="home_page" ref="home">
        <!-- <transition name="welcome">
            <div class="welcome_wrap" v-if="!isShowedWelcome">
                <welcome @handleEnter="handleEnter" class="absolute_center"></welcome>
            </div>
        </transition> -->
        <custom-head></custom-head>
        <div class="banner_wrap">
            <swiper ref="mySwiper" :options="swiperOptions" class="banner_swiper_wrap w_h_100 swiper-no-swiping"
                @slideChangeTransitionStart="bannerChangeTransitionStart">
                <swiper-slide v-for="(item, index) in bannerList" :key="index" class="banner_swiper_slide">
                    <div class="main_graph_wrap">
                        <img :src="item.mainGraph" class="main_graph absolute_center w_h_100" alt="">
                    </div>
                    <img v-for="(childItem, childIndex) of item.secondaryGraph" :key="childIndex" :src="childItem"
                        class="secondary_graph absolute_r_t clickable" @click="handelSelectSlide(index, childIndex)" alt="">
                    <div class="banner_text_wrap flex_align_start">
                        <img :src="item.bannerText" alt="" class="banner_text">
                        <img src="@/assets/pc/img/next.png" alt="" class="next_btn clickable"
                            @click="handleNextBtnFromBanner">
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <h1 class="select_none">DO IT FOR CHANGE</h1>
        </div>
        <div class="introduction_wrap">
            <div class="unlock_wrap">
                <img class="unlock_title_tag" src="@/assets/pc/img/unlock_title_tag.png" alt="">
                <div class="text_content_wrap flex_align_start">
                    <p class="text_content">
                        解套GETO致力于打造好玩有趣、年轻时尚的两性情趣生活品牌，我们在甜酷女孩的生活视角中汲取灵感，倡导用积极乐观、激情洒脱的生活态度去面对现实中的困惑，相信一切都能迎刃而解。</p>
                    <div class="unlock_img_wrap">
                        <img :src="pcCosBaseUrl + 'home_unlock_img_1.png'" alt="">
                        <img class="absolute_l_t" :class="isUnlockImgOpen ? 'open' : ''"
                            :src="pcCosBaseUrl + 'home_unlock_img_2.png'" alt="">
                    </div>
                </div>
                <div class="more_btn flex_align_center clickable" @mouseenter="isMoreTagActived = true"
                    @mouseleave="isMoreTagActived = false" @click="$gotoPage('about')">
                    <span>more</span>
                    <img class="more_tag_normal" v-show="!isMoreTagActived" src="@/assets/pc/img/more_tag_normal.png"
                        alt="">
                    <transition name="more-tag">
                        <img class="more_tag" v-show="isMoreTagActived" src="@/assets/pc/img/more_tag.png" alt="">
                    </transition>
                </div>
            </div>
            <div class="video_wrap" ref="videoWrap">
                <img class="video_title_tag" src="@/assets/pc/img/video_title_tag.png" alt="">
                <video controls :src="baseCosBaseUrl + 'home_video.mp4'"
                    :poster="baseCosBaseUrl + 'home_video_poster.png'"></video>
                <img class="rotate_tag rotate" src="@/assets/pc/img/rotate_tag.png" alt="">
            </div>
        </div>
        <!-- <div class="product_container" id="product" ref='productContainer'>
            <div class="wrapper">
                <div class="product_content" ref="productContent">
                    <div class="title_wrap">
                        <div class="title" ref="productTitle">
                            <img class="product_title_tag" src="@/assets/pc/img/product_title_tag.png" alt="">
                            <div class="product_name_wrap" ref='productTitlesWrap'>
                                <div class="empty" ref='productEmpty'></div>
                                <div class="item clickable" v-for='(item, index) of productList'
                                    v-show='index<showProductNameNum' :key='index' @click='handleProductTitle(index+1)'>
                                    {{item.title}}<span>0{{index+1}}</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="product_wrap">
                        <div class="product_item" :class="'product_item_'+(index+1)"
                            v-for='(item, index) of productList' :key='index'>
                            <img :src="item.poster" alt="">
                            <div class="product_intro clickable" @click="$gotoPage('product', {id: item.id})">
                                <div class="product_name" :class="'product_name_'+(index+1)">
                                    {{item.title}}<span>0{{index+1}}</span></div>
                                <div class="en">{{item.en}}</div>
                                <img :src="item.imgs[0]" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="doit_wrap" ref='doitWrap'>
                    <div class="wrapper flex_col flex_justify_center" ref='tempDoitWrap'>
                        <img ref='doit1' :style="{left: clientWidth/2+'px'}"
                            src="@/assets/pc/img/doitforchange_tag_1.png" alt="">
                        <img ref='doit2' :style="{right: clientWidth/2+'px'}"
                            src="@/assets/pc/img/doitforchange_tag_2.png" alt="">
                    </div>
                </div>
            </div>
        </div> -->
        <div class="product_wrap" ref='productContainer'>
            <div class="title_wrap select_none pointer_events_none">
                <div class="temp_bg"></div><div class="title_tag_wrap"><img class="title_tag" src="@/assets/pc/img/product_title_tag.png" alt=""></div>
            </div>
            <div class="product_item" :id="`product-item${index + 1}`" v-for="(item, index) of productList" :key="index">
                <img class="poster clickable" :src="item.poster" alt="" @click="$gotoPage('product', { id: item.id })">
                <div class="name_wrap flex_col flex_align_center" :class="`name_wrap_${index + 1}`">
                    <div class="cn clickable" :class="`cn_${index + 1}`"
                        @click="handleProductName(`product-item${index + 1}`)">{{ item.title }} <span>0{{ index + 1 }}</span>
                    </div>
                    <div class="en">{{ item.en }}</div>
                    <img class="small_img clickable" :class="`small_img_${index + 1}`" :src="item.imgs[0]" alt=""
                        @click="$gotoPage('product', { id: item.id })">
                    <div class="line" :class="`line_${index + 1}`"></div>
                </div>
            </div>
        </div>
        <div class="doit_wrap" ref='doitWrap'>
            <div class="wrapper flex_col flex_justify_center" ref='tempDoitWrap'>
                <img ref='doit1' :style="{ left: clientWidth / 2 + 'px' }" src="@/assets/pc/img/doitforchange_tag_1.png" alt="">
                <img ref='doit2' :style="{ right: clientWidth / 2 + 'px' }" src="@/assets/pc/img/doitforchange_tag_2.png" alt="">
            </div>
        </div>
        <div class="peripheral_container" ref="peripheralContainer">
            <div class="peripheral_wrap">
                <img class="peripheral_title_tag" src="@/assets/pc/img/peripheral_title_tag.png" alt="">
                <div class="peripheral_img_wrap flex_align_center flex_justify_between">
                    <img class="rotate_tag" src="@/assets/pc/img/rotate_tag_2.png" alt="">
                    <img class="peripheral_img" :src="pcCosBaseUrl + 'home_peripheral_img.jpg'" alt="">
                </div>
                <div class="peripheral_product_container">
                    <div class="temp_wrapper" ref="peripheralProductWrapper">
                        <div class="wrapper flex_align_end w_h_100">
                            <div class="peripheral_product_wrap"
                                :style="{ transform: 'translate3d(0,-' + (clientHeight - peripheraItemHeight) / 2 + 'px,0)' }">
                                <swiper ref="peripheralSwiper" :options="peripheralSwiperOptions"
                                    class="peripheral_swiper w_h_100 swiper-no-swiping">
                                    <swiper-slide v-for="(item, index) in peripheralList" :key="index"
                                        class="peripheral_swiper_slide">
                                        <div class="periphera_item absolute_l_b flex_col flex_align_start flex_justify_end clickable"
                                            :class="!isLoaded ? 'top_speed' : ''"
                                            :style="{ backgroundImage: 'url(' + item.img + ')' }" :data-id="item.id">
                                            <span class="en">{{ item.en }}</span>
                                            <span class="cn">{{ item.cn }}</span>
                                            <span class="price">￥{{ item.price }}</span>
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="peripheral_scroll_wrap" :style="{ height: peripheralScrollHeight + 'px' }" ref="peripheralScrollWrap">
            </div>
        </div>
        <div class="series_wrap" :style="{ backgroundImage: 'url(' + pcCosBaseUrl + 'home_series_img.jpg)' }">
            <img class="more_btn clickable" src="@/assets/pc/img/more_btn.png" @click="$windowOpen(pcJDShopHomeUrl)" alt="">
        </div>
        <div class="news_wrap">
            <div class="title_wrap flex_col">
                <span>TOPICAL NEWS</span>
                <span>时事新闻</span>
            </div>
            <div class="news_content">
                <div class="news_item flex_align_center clickable" v-for="(item, index) of newsData" :key="index"
                    @click="$windowOpen(item.address)">
                    <div class="news_detail flex_col flex_align_start">
                        <span class="time">{{ item.time }}</span>
                        <span class="title">{{ item.title }}</span>
                        <p class="content">{{ item.content }}</p>
                        <img class="to_right_tag" src="@/assets/pc/img/to_right_tag.png" alt="">
                    </div>
                    <img class="new_img" :src="item.coverImg" alt="">
                </div>
            </div>
        </div>
        <div class="hot_recommend_wrap">
            <div class="title_wrap flex_col">
                <span>MORE PRODUCTS</span>
                <span>热门推荐</span>
            </div>
            <div class="hot_recommend_content">
                <swiper ref="hotSwiper" :options="hotSwiperOptions" class="hot_swiper w_h_100 swiper-no-swiping">
                    <swiper-slide v-for="(item, index) in hotList" :key="item.id" class="hot_swiper_slide">
                        <img :src="item.img" :data-id="item.id" class="hot_img absolute_center clickable" alt="">
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
                <img class="prev_btn clickable" src="@/assets/pc/img/prev_2.png" @click="hotSwiper.slidePrev()" alt="">
                <img class="next_btn clickable" src="@/assets/pc/img/next_2.png" @click="hotSwiper.slideNext()" alt="">
            </div>
        </div>
        <custom-foot></custom-foot>
        <transition name="fade">
            <div class="gif_wrap" v-show="isShowGif">
                <img class="text_gif_bg w_h_100 absolute_center" src="@/assets/pc/img/text_gif_bg.png" alt="">
                <img class="text_gif_1 w_h_100" src="@/assets/pc/img/text_jie_1.gif" alt="">
                <img class="text_gif_2 w_h_100 clickable" src="@/assets/pc/img/text_jie_2.gif" @click="$gotoPage('about')"
                    alt="">
            </div>
        </transition>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import welcome from '@/components/pc/welcome.vue'

import { baseCosBaseUrl, pcCosBaseUrl, pcJDShopHomeUrl } from '@/config/evn.js'
import { mapState } from 'vuex'

let fullWidth = document.documentElement.clientWidth // 这里通过获得屏幕宽度控制swiper左右item之间的距离
let swiperMargin = 60 * fullWidth / 1920
let hotSwiperMargin = 80 * fullWidth / 1920
let vm = null;
export default {
    name: 'home',
    data() {
        return {
            timmer: null,
            isLoaded: false, // 用于刷新或者返回当前页面时快速完成peripheralSwiper动画
            baseCosBaseUrl,
            pcCosBaseUrl, // pc端腾讯cos图片文件夹目录
            pcJDShopHomeUrl, // 京东店铺首页
            // isShowedWelcome: false, // 是否已经显示了welcome界面
            isMoreTagActived: false, // 控制MoreTag动画
            isShowGif: false, // 控制gif图的显示和隐藏
            clientHeight: 0, // 浏览器可见区域高度
            clientWidth: 0, // 浏览器可见区域宽度
            showProductNameNum: 0, // 显示productTitle个数
            productContainerTop: 0, // productContainer距离顶部的距离
            productContainerHeight: 0, // productContainer本身高度
            productTitleHeight: 0, // productTitle本身高度
            productContentHeight: 0, // productContent本身高度
            productTitleFixed: false, // 显示productTitle是否处于fixed定位
            productEmptyHeight: 0, // productEmpty本身的高度
            productItemHeight: 0, // productItem本身高度
            productNameTop1: 0, // 第1个productName距离body容器顶部的距离
            productNameTop2: 0, // 第2个productName距离body容器顶部的距离
            productNameTop3: 0, // 第3个productName距离body容器顶部的距离
            productNameTop4: 0, // 第4个productName距离body容器顶部的距离
            productNameTop5: 0, // 第5个productName距离body容器顶部的距离
            productItemTop1: 0, // 第1个productItem距离body容器顶部的距离
            productItemTop2: 0, // 第2个productItem距离body容器顶部的距离
            productItemTop3: 0, // 第3个productItem距离body容器顶部的距离
            productItemTop4: 0, // 第4个productItem距离body容器顶部的距离
            videoWrapTop: 0, // video_wrap距离顶端的高度
            isUnlockImgOpen: false,
            doitWrapTop: 0, // doitWrap距离顶部距离
            doit1Top: 0, // doit1距离顶部距离
            peripheralScrollWrapTop: 0, // 周边产品滚动区域距离顶部的距离
            bannerActiveIndex: 0, // banner当前激活的index
            peripheralActiveIndex: 0, // 周边产品当前激活的index
            peripheraItemHeight: 806 * fullWidth / 1920, // 周边产品item放大后的高度（用来让周边产品容器居中显示）
            swiperOptions: {
                effect: 'fade',
                slidesPerView: '1',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            peripheralSwiperOptions: {
                slidesPerView: '1',
                autoplay: false,
                loop: true,
                speed: 300,
                loopAdditionalSlides: 1,
                initialSlide: 1,
                spaceBetween: swiperMargin,
                on: {
                    init: function (swiper) {
                        //Swiper初始化了
                        vm.peripheralSwiperInit()
                    },
                },
            },
            hotSwiperOptions: {
                slidesPerView: '3',
                centeredSlides: true,
                loop: true,
                preventClicksPropagation: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                spaceBetween: hotSwiperMargin,
                on: {
                    init: function (swiper) {
                        //Swiper初始化了
                        vm.hotSwiperInit()
                    },
                }
            },
            bannerList: [
                {
                    bannerText: pcCosBaseUrl + 'home_banner_1_text.png',
                    mainGraph: pcCosBaseUrl + 'home_banner_1_main.jpg',
                    secondaryGraph: [
                        pcCosBaseUrl + 'home_banner_1_secondary_1.png',
                        pcCosBaseUrl + 'home_banner_1_secondary_2.png'
                    ]
                }, {
                    bannerText: pcCosBaseUrl + 'home_banner_2_text.png',
                    mainGraph: pcCosBaseUrl + 'home_banner_2_main.jpg',
                    secondaryGraph: [
                        pcCosBaseUrl + 'home_banner_2_secondary_1.png',
                        pcCosBaseUrl + 'home_banner_2_secondary_2.png'
                    ]
                }, {
                    bannerText: pcCosBaseUrl + 'home_banner_3_text.png',
                    mainGraph: pcCosBaseUrl + 'home_banner_3_main.jpg',
                    secondaryGraph: [
                        pcCosBaseUrl + 'home_banner_3_secondary_1.png',
                        pcCosBaseUrl + 'home_banner_3_secondary_2.png'
                    ]
                }
            ],
            productList: [
                {
                    id: 4,
                    title: '超润超薄',
                    color: '',
                    en: 'Silver ultra-moist ultra-thin condom of jietao',
                    poster: pcCosBaseUrl + 'home_product_1.png',
                    imgs: [
                        pcCosBaseUrl + 'home_product_1-1.png',
                        pcCosBaseUrl + 'home_product_1-2.png'
                    ]
                }, {
                    id: 5,
                    title: '持久延时',
                    color: 'BLUE',
                    en: 'Long-lasting condom of jietao',
                    poster: pcCosBaseUrl + 'home_product_2.png',
                    imgs: [
                        pcCosBaseUrl + 'home_product_2-1.png',
                        pcCosBaseUrl + 'home_product_2-2.png'
                    ]
                }, {
                    id: 3,
                    title: '玻尿酸款',
                    color: '',
                    en: 'Pink hyaluronic acid condom of jietao',
                    poster: pcCosBaseUrl + 'home_product_3.png',
                    imgs: [
                        pcCosBaseUrl + 'home_product_3-1.png',
                        pcCosBaseUrl + 'home_product_3-2.png'
                    ]
                }, {
                    id: 2,
                    title: '超薄款',
                    color: 'BLACK',
                    en: 'Ultra-thin condom of jietao',
                    poster: pcCosBaseUrl + 'home_product_4.png',
                    imgs: [
                        pcCosBaseUrl + 'home_product_4-1.png',
                        pcCosBaseUrl + 'home_product_4-2.png'
                    ]
                }, {
                    id: 1,
                    title: '螺纹颗粒',
                    color: '',
                    en: 'Green thread pellet condom of jietao',
                    poster: pcCosBaseUrl + 'home_product_5.png',
                    imgs: [
                        pcCosBaseUrl + 'home_product_5-1.png',
                        pcCosBaseUrl + 'home_product_5-2.png'
                    ]
                }
            ],
            peripheralList: [
                {
                    id: 8,
                    en: 'Silk Scarf',
                    cn: '丝巾',
                    price: 49.9,
                    img: pcCosBaseUrl + 'home_peripheral_01.png'
                }, {
                    id: 6,
                    en: 'Lubricant',
                    cn: '润滑剂',
                    price: 29.9,
                    img: pcCosBaseUrl + 'home_peripheral_02.png'
                }, {
                    id: 9,
                    en: 'Chest Paste',
                    cn: '胸贴',
                    price: 66.9,
                    img: pcCosBaseUrl + 'home_peripheral_03.png'
                }, {
                    id: 7,
                    en: 'Key Buckle',
                    cn: '钥匙扣',
                    price: 29.9,
                    img: pcCosBaseUrl + 'home_peripheral_04.png'
                }, {
                    id: 10,
                    en: 'Blessing Bag',
                    cn: '福袋',
                    price: 29.9,
                    img: pcCosBaseUrl + 'home_peripheral_05.png'
                }
            ],
            hotList: [
                {
                    id: 9,
                    img: pcCosBaseUrl + 'home_hot_01.png'
                }, {
                    id: 8,
                    img: pcCosBaseUrl + 'home_hot_02.png'
                }, {
                    id: 6,
                    img: pcCosBaseUrl + 'home_hot_03.png'
                }, {
                    id: 10,
                    img: pcCosBaseUrl + 'home_hot_04.png'
                }, {
                    id: 7,
                    img: pcCosBaseUrl + 'home_hot_05.png'
                }
            ]
        }
    },
    created() {
        vm = this
        // this.isShowedWelcome = JSON.parse(this.$handleLocalStorage('get', 'isShowedWelcome'))
        this.peripheralActiveIndex = this.peripheralList.length + this.peripheralSwiperOptions.loopAdditionalSlides * 2
        this.peripheralSwiperOptions.initialSlide = this.peripheralList.length
    },
    mounted() {
        // 用于刷新或者返回当前页面时快速完成peripheralSwiper动画
        this.peripheralSwiper.params.speed = 0

        this.clientHeight = window.innerHeight || document.documentElement.clientHeight
        this.clientWidth = window.innerWidth || document.documentElement.clientWidth
        setTimeout(() => {
            this.productContainerTop = this.$refs.productContainer.offsetTop
            // this.productContainerHeight = this.$refs.productContainer.offsetHeight
            // this.productTitleHeight = this.$refs.productTitle.offsetHeight
            // this.productContentHeight = this.$refs.productContent.offsetHeight
            // this.productEmptyHeight = this.$refs.productEmpty.offsetHeight
            // this.productItemHeight = this.$refs.productTitlesWrap.offsetHeight - this.productEmptyHeight
            // this.productNameTop1 = document.getElementsByClassName('product_name_1')[0].offsetTop + this.productContainerTop
            // this.productNameTop2 = document.getElementsByClassName('product_name_2')[0].offsetTop + this.productContainerTop
            // this.productNameTop3 = document.getElementsByClassName('product_name_3')[0].offsetTop + this.productContainerTop
            // this.productNameTop4 = document.getElementsByClassName('product_name_4')[0].offsetTop + this.productContainerTop
            // this.productNameTop5 = document.getElementsByClassName('product_name_5')[0].offsetTop + this.productContainerTop
            // this.productItemTop1 = document.getElementsByClassName('product_item_1')[0].offsetTop + this.productContainerTop
            // this.productItemTop2 = document.getElementsByClassName('product_item_2')[0].offsetTop + this.productContainerTop
            // this.productItemTop3 = document.getElementsByClassName('product_item_3')[0].offsetTop + this.productContainerTop
            // this.productItemTop4 = document.getElementsByClassName('product_item_4')[0].offsetTop + this.productContainerTop
            this.videoWrapTop = this.$refs.videoWrap.offsetTop
            this.doitWrapTop = this.$refs.doitWrap.offsetTop - this.clientHeight
            this.doit1Top = this.$refs.doit1.offsetTop + this.doitWrapTop
            this.peripheralScrollWrapTop = this.$refs.peripheralScrollWrap.offsetTop + this.$refs.peripheralContainer.offsetTop

            this.handleScroll()
            // this.handleScrollProductTitle()
            // this.handleScrollProductName()
            this.handleScrollDoit()
            this.handleScrollPeripheral()
            document.addEventListener('scroll', this.handleScroll)
            // document.addEventListener('scroll', this.handleScrollProductTitle)
            // document.addEventListener('scroll', this.handleScrollProductName)
            document.addEventListener('scroll', this.handleScrollDoit)
            document.addEventListener('scroll', this.handleScrollPeripheral)

            // 用于刷新或者返回当前页面时快速完成peripheralSwiper动画
            this.peripheralSwiper.params.speed = 300
            setTimeout(() => {
                this.isLoaded = true
            }, 0);
        })
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll)
        // document.removeEventListener('scroll', this.handleScrollProductTitle)
        // document.removeEventListener('scroll', this.handleScrollProductName)
        document.removeEventListener('scroll', this.handleScrollDoit)
        document.removeEventListener('scroll', this.handleScrollPeripheral)
        window.removeEventListener('resize', this.handleResize)
    },
    computed: {
        ...mapState(['newsData']),
        mySwiper() {
            return this.$refs.mySwiper.swiper
        },
        peripheralSwiper() {
            return this.$refs.peripheralSwiper.swiper
        },
        hotSwiper() {
            return this.$refs.hotSwiper.swiper
        },
        // 周边产品绝对定位滚动区域高度
        peripheralScrollHeight() {
            return 1000 * this.peripheralList.length
        }
    },
    methods: {
        // handleEnter() {
        //     this.isShowedWelcome = true
        //     this.$handleLocalStorage('set', 'isShowedWelcome', true)
        // },
        handelSelectSlide(parentIndex, childIndex) {
            // 标识当前是第几个主图，swiper第一个的index为1
            parentIndex += 1
            // 标识点击的是第几个副图
            childIndex += 1
            switch (parentIndex) {
                case 1:
                    if (childIndex === 1) {
                        this.mySwiper.slideNext()
                    } else {
                        this.mySwiper.slideTo(2)
                    }
                    break;
                case 2:
                    if (childIndex === 1) {
                        this.mySwiper.slideNext()

                    } else {
                        this.mySwiper.slidePrev(0)
                    }
                    break;
                case 3:
                    if (childIndex === 1) {
                        this.mySwiper.slideTo(0)
                    } else {
                        this.mySwiper.slidePrev()
                    }
                    break;
                default:
                    break;
            }

        },
        handleProductName(id) {
            document.getElementById(id).scrollIntoView(false)
        },
        handleProductTitle(index) {
            if (index == 1) {
                window.scrollTo(0, this.productItemTop1 - this.productTitleHeight)
            } else if (index == 2) {
                window.scrollTo(0, this.productItemTop2 - this.productTitleHeight)
            } else if (index == 3) {
                window.scrollTo(0, this.productItemTop3 - this.productTitleHeight)
            } else if (index == 4) {
                window.scrollTo(0, this.productItemTop4 - this.productTitleHeight)
            }
        },
        handleResize() {
            if (this.timmer) {
                clearInterval(this.timmer)
            }
            this.timmer = setTimeout(() => {
                this.productContainerTop = this.$refs.productContainer.offsetTop
                // this.productContainerHeight = this.$refs.productContainer.offsetHeight
                // this.productTitleHeight = this.$refs.productTitle.offsetHeight
                // this.productContentHeight = this.$refs.productContent.offsetHeight
                // this.productEmptyHeight = this.$refs.productEmpty.offsetHeight
                // this.productItemHeight = this.$refs.productTitlesWrap.offsetHeight - this.productEmptyHeight
                // this.productNameTop1 = document.getElementsByClassName('product_name_1')[0].offsetTop + this.productContainerTop
                // this.productNameTop2 = document.getElementsByClassName('product_name_2')[0].offsetTop + this.productContainerTop
                // this.productNameTop3 = document.getElementsByClassName('product_name_3')[0].offsetTop + this.productContainerTop
                // this.productNameTop4 = document.getElementsByClassName('product_name_4')[0].offsetTop + this.productContainerTop
                // this.productNameTop5 = document.getElementsByClassName('product_name_5')[0].offsetTop + this.productContainerTop
                // this.productItemTop1 = document.getElementsByClassName('product_item_1')[0].offsetTop + this.productContainerTop
                // this.productItemTop2 = document.getElementsByClassName('product_item_2')[0].offsetTop + this.productContainerTop
                // this.productItemTop3 = document.getElementsByClassName('product_item_3')[0].offsetTop + this.productContainerTop
                // this.productItemTop4 = document.getElementsByClassName('product_item_4')[0].offsetTop + this.productContainerTop
                this.videoWrapTop = this.$refs.videoWrap.offsetTop
                this.doitWrapTop = this.$refs.doitWrap.offsetTop - this.clientHeight
                this.doit1Top = this.$refs.doit1.offsetTop + this.doitWrapTop
                this.peripheralScrollWrapTop = this.$refs.peripheralScrollWrap.offsetTop + this.$refs.peripheralContainer.offsetTop

                this.handleScroll()
                // this.handleScrollProductTitle()
                // this.handleScrollProductName()
                this.handleScrollDoit()
                this.handleScrollPeripheral()

                setTimeout(() => {
                    this.mySwiper.update()
                    this.peripheralSwiper.update()
                    this.hotSwiper.update()
                }, 0);
                this.timmer = null
            }, 500);
        },
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

            if (scrollTop >= this.videoWrapTop - this.clientHeight) {
                if (!this.isUnlockImgOpen) this.isUnlockImgOpen = true
            } else {
                if (this.isUnlockImgOpen) this.isUnlockImgOpen = false
            }

            if (scrollTop < this.productContainerTop) {
                if (this.isShowGif) this.isShowGif = false
            } else if (scrollTop >= this.productContainerTop && scrollTop < this.peripheralScrollWrapTop - this.clientHeight + this.peripheralScrollHeight + this.clientHeight / 2) {
                if (!this.isShowGif) this.isShowGif = true
            } else {
                if (this.isShowGif) this.isShowGif = false
            }
        },
        // handleScrollProductTitle() {
        //     let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        //     if (scrollTop < this.productContainerTop) {

        //         if (this.productTitleFixed) {
        //             this.productTitleFixed = false
        //             this.$refs.productTitle.style.position = 'relative'
        //         }

        //     } else if (scrollTop >= this.productContainerTop) {
        //         if (!this.productTitleFixed) {
        //             this.productTitleFixed = true
        //             this.$refs.productTitle.style.position = 'fixed'
        //         }

        //     }
        // },
        // handleScrollProductName() {
        //     let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
        //     if (scrollTop >= this.productNameTop5 - this.productEmptyHeight - this.productItemHeight * 4) {
        //         this.$refs.productTitle.style.transform = 'translate3d(0, ' + -(scrollTop - this.productNameTop5 + this.productEmptyHeight + this.productItemHeight * 4) + 'px, 0)'
        //         this.showProductNameNum = 4
        //     } else if (scrollTop >= this.productNameTop4 - this.productEmptyHeight - this.productItemHeight * 3 - 10) {
        //         this.$refs.productTitle.style.transform = 'translate3d(0, 0, 0)'
        //         this.showProductNameNum = 4
        //     } else if (scrollTop >= this.productNameTop3 - this.productEmptyHeight - this.productItemHeight * 2 - 10) {
        //         this.$refs.productTitle.style.transform = 'translate3d(0, 0, 0)'
        //         this.showProductNameNum = 3
        //     } else if (scrollTop >= this.productNameTop2 - this.productEmptyHeight - this.productItemHeight * 1 - 10) {
        //         this.$refs.productTitle.style.transform = 'translate3d(0, 0, 0)'
        //         this.showProductNameNum = 2
        //     } else if (scrollTop >= this.productNameTop1 - this.productEmptyHeight - this.productItemHeight * 0 - 10) {
        //         this.$refs.productTitle.style.transform = 'translate3d(0, 0, 0)'
        //         this.showProductNameNum = 1
        //     } else {
        //         this.$refs.productTitle.style.transform = 'translate3d(0, 0, 0)'
        //         this.showProductNameNum = 0
        //     }
        // },
        handleScrollDoit() {
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            if (scrollTop >= this.doit1Top) {
                this.$refs.doit1.style.transform = 'translate3d(' + (-(scrollTop - this.doit1Top) * 2 + this.clientWidth) + 'px, 0, 0)'
                this.$refs.doit2.style.transform = 'translate3d(' + ((scrollTop - this.doit1Top) * 2 - this.clientWidth) + 'px, 0, 0)'
            }
            if (scrollTop >= this.doitWrapTop + this.clientHeight) {
                this.$refs.tempDoitWrap.style.position = 'fixed'
            } else {
                this.$refs.tempDoitWrap.style.position = 'relative'
            }

            if (Math.abs(scrollTop - this.doit1Top) >= this.clientWidth * 0.7) {
                this.$refs.tempDoitWrap.style.display = 'none'
            } else {
                this.$refs.tempDoitWrap.style.display = 'flex'
            }
        },
        handleScrollPeripheral() {
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
            let peripheralDifference = this.peripheralScrollWrapTop - this.clientHeight
            let index
            if (scrollTop < peripheralDifference) {
                this.$refs.peripheralProductWrapper.style.position = 'relative'
                index = this.peripheralList.length + this.peripheralSwiperOptions.loopAdditionalSlides * 2

            } else if (scrollTop >= peripheralDifference && scrollTop < peripheralDifference + this.peripheralScrollHeight) {
                this.$refs.peripheralProductWrapper.style.position = 'fixed'
                index = this.peripheralList.length + this.peripheralSwiperOptions.loopAdditionalSlides * 2 - Math.floor((scrollTop - peripheralDifference) / (this.peripheralScrollHeight / this.peripheralList.length))
            } else {
                this.$refs.peripheralProductWrapper.style.position = 'absolute'
                index = this.peripheralList.length + this.peripheralSwiperOptions.loopAdditionalSlides * 2 - (this.peripheralList.length - 1)
            }
            if (this.peripheralActiveIndex != index) {
                this.peripheralActiveIndex = index
                this.peripheralSwiper.slideTo(index)
            }
        },
        bannerChangeTransitionStart() {
            this.bannerActiveIndex = this.mySwiper.activeIndex
        },
        handleNextBtnFromBanner() {
            if (this.bannerActiveIndex == this.bannerList.length - 1) return this.mySwiper.slideTo(0)
            this.mySwiper.slideNext()
        },
        hotSwiperInit() {
            let hotImgs = Array.from(document.getElementsByClassName('hot_img'));
            if (!Array.isArray(hotImgs)) return;
            hotImgs.forEach((item) => {
                item.addEventListener('click', (e) => {
                    this.$gotoPage('product', { id: +e.currentTarget.dataset.id })
                })
            })
        },
        peripheralSwiperInit() {
            let peripheralImgs = Array.from(document.getElementsByClassName('periphera_item'));
            if (!Array.isArray(peripheralImgs)) return;
            peripheralImgs.forEach((item) => {
                item.addEventListener('click', (e) => {
                    this.$gotoPage('product', { id: +e.target.dataset.id })
                })
            })
        }
    },
    components: {
        welcome,
        swiper,
        swiperSlide
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/mixin.scss';

@-webkit-keyframes imgScale {

    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.3);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate();
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes moreTag {
    0% {
        transform: translate3d(-200%, 200%, 0);
    }

    100% {
        transform: translate3d(200%, -200%, 0);
    }
}

.rotate {
    animation: rotate 3s infinite linear;
}

.more-tag-enter {
    transform: translate3d(0, 0, 0);
}

.more-tag-enter-to {
    transform: translate3d(200%, -200%, 0);
}

.more-tag-enter-active {
    transition: all 0.4S linear;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}

.welcome-leave-to {
    opacity: 0;
    width: 0 !important;
    height: 0 !important;
}

.welcome-leave-active {
    transition: all 0.2s ease;
}

.home_page {
    position: relative;
    background-color: #171717;
    &.no_height {
        height: 0 !important;
    }

    .welcome_wrap {
        position: fixed;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100vh;
        transform: translate3d(-50%, -50%, 0);
        z-index: 10;
        overflow: hidden;
    }

    .gif_wrap {
        position: fixed;
        right: formatPX_1920(80px);
        bottom: formatPX_1920(46px);
        z-index: 2;
        width: formatPX_1920(230px);
        height: formatPX_1920(230px);

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
        }

        &:hover {
            .text_gif_1 {
                opacity: 0;
            }

            .text_gif_2 {
                opacity: 1;
            }
        }

        .text_gif_bg {
            width: formatPX_1920(300px);
            height: formatPX_1920(300px);
        }

        .text_gif_1 {
            opacity: 1;
        }

        .text_gif_2 {
            opacity: 0;
        }

    }

    .banner_wrap {
        position: relative;
        width: 100%;
        height: formatPX_1920(1080px);
        overflow: hidden;

        .banner_swiper_wrap {
            .banner_swiper_slide {
                position: relative;
                height: 100%;
                background-size: cover;
                overflow: hidden;

                &.swiper-slide-active {
                    .main_graph {
                        animation: imgScale 6s ease-out infinite;
                    }
                }

                .main_graph_wrap {
                    position: relative;
                    width: formatPX_1920(1600px);
                    height: 100%;
                }

                .secondary_graph {
                    width: formatPX_1920(162px);
                    height: 100%;
                    border-left: formatPX_1920(2px) solid #fff;

                    &:nth-child(2) {
                        right: formatPX_1920(162px);
                    }

                    &:nth-child(3) {
                        right: 0;
                    }
                }

            }

            ::v-deep.swiper-pagination {
                bottom: formatPX_1920(79px);
                width: auto;
                text-align: left;
                padding-left: formatPX_1920(205px);

                .swiper-pagination-bullet {
                    width: formatPX_1920(20px);
                    height: formatPX_1920(20px);
                    background-color: transparent;
                    border: formatPX_1920(2px) solid #fff;
                    opacity: 1;
                    margin: 0 formatPX_1920(6px);
                }

                .swiper-pagination-bullet-active {
                    background-color: #fff;
                }
            }
        }

        h1 {
            position: absolute;
            left: formatPX_1920(211px);
            bottom: formatPX_1920(239px);
            z-index: 1;
            font-family: HurmeGeometricSans3-Black;
            font-size: formatPX_1920(60px);
            letter-spacing: formatPX_1920(3px);
            color: #fff;
        }

        .banner_text_wrap {
            position: absolute;
            left: formatPX_1920(210px);
            bottom: formatPX_1920(147px);
            z-index: 1;
            height: formatPX_1920(68px);

            .banner_text {
                height: formatPX_1920(58px);
            }

            .next_btn {
                width: formatPX_1920(72px);
                height: 100%;
                margin-left: formatPX_1920(30px);
            }
        }

    }

    .introduction_wrap {
        background-color: #fff;
        .unlock_wrap {
            padding: formatPX_1920(247px) 0 0 formatPX_1920(210px);

            .unlock_title_tag {
                width: formatPX_1920(347px);
                height: formatPX_1920(124px);
            }

            .text_content_wrap {
                width: 100%;
                height: formatPX_1920(343px);

                p {
                    width: formatPX_1920(590px);
                    line-height: formatPX_1920(48px);
                    font-family: SourceHanSansCN-Regular;
                    font-size: formatPX_1920(24px);
                    text-align: left;
                    margin: formatPX_1920(45px) formatPX_1920(310px) 0 0;
                }

                .unlock_img_wrap {
                    position: relative;
                    width: formatPX_1920(585px);
                    height: 100%;

                    img {
                        width: formatPX_1920(550px);
                        height: 100%;
                        transform-origin: 0 100%;
                        transition: transform .5s ease-in;

                        &:first-child {
                            margin-left: auto;
                        }
                    }

                    .open {
                        transform: rotate(-35deg);
                    }
                }
            }

            .more_btn {
                position: relative;
                width: formatPX_1920(174px);
                height: formatPX_1920(50px);
                font-size: formatPX_1920(28px);
                border: formatPX_1920(1px) solid #000;
                border-radius: formatPX_1920(25px);
                overflow: hidden;
                padding-left: formatPX_1920(30px);
                margin-top: formatPX_1920(45px);

                &:hover {
                    color: #fff;
                    border-color: #fff;
                    background: #0000ff;

                    .more_tag {
                        animation: moreTag 0.8s infinite linear 0.4s;
                    }
                }

                .more_tag,
                .more_tag_normal {
                    position: absolute;
                    top: formatPX_1920(13px);
                    right: formatPX_1920(23px);
                    width: formatPX_1920(31px);
                    height: formatPX_1920(24px);
                }
            }
        }

        .video_wrap {
            position: relative;
            overflow: hidden;
            padding: formatPX_1920(303px) 0 0 formatPX_1920(210px);

            .video_title_tag {
                width: formatPX_1920(485px);
                height: formatPX_1920(124px);
            }

            video {
                width: formatPX_1920(1350px);
                height: auto;
                margin: formatPX_1920(117px) 0 formatPX_1920(60px);
            }

            .rotate_tag {
                position: absolute;
                width: formatPX_1920(267px);
                height: formatPX_1920(267px);
                right: formatPX_1920(193px);
                top: formatPX_1920(373px);
            }
        }
    }

    // .product_container {
    //     position: relative;
    //     width: 100%;
    //     color: #fff;
    //     text-align: left;

    //     .wrapper {
    //         position: relative;
    //         width: 100%;
    //         height: 100%;
    //         background-color: #171717;
    //         z-index: 1;

    //         .product_content {
    //             position: relative;
    //             padding-bottom: formatPX_1920(160px);

    //             .title_wrap {
    //                 width: 100%;
    //                 height: formatPX_1920(410px);

    //                 .title {
    //                     position: relative;
    //                     left: 0;
    //                     top: 0;
    //                     width: formatPX_1920(1100px);
    //                     height: formatPX_1920(410px);
    //                     font-family: HurmeGeometricSans3-Black;
    //                     font-size: formatPX_1920(68px);
    //                     line-height: formatPX_1920(105px);
    //                     background-color: #171717;
    //                     padding: formatPX_1920(140px) 0 0 formatPX_1920(360px);

    //                     .product_name_wrap {
    //                         position: absolute;
    //                         left: formatPX_1920(1120px);
    //                         top: 0;
    //                         z-index: 101;
    //                         width: formatPX_1920(470px);
    //                         height: formatPX_1920(207px);
    //                         font-family: PingFang-SC-Bold;
    //                         font-size: formatPX_1920(30px);
    //                         display: flex;
    //                         flex-direction: column;

    //                         .empty {
    //                             position: relative;
    //                             width: 100%;
    //                             height: formatPX_1920(140px);
    //                             background-color: #171717;
    //                             flex-shrink: 0;

    //                             &::before {
    //                                 content: '';
    //                                 display: block;
    //                                 position: absolute;
    //                                 z-index: 1;
    //                                 bottom: 0;
    //                                 transform: translateY(50%);
    //                                 width: 100%;
    //                                 height: formatPX_1920(20px);
    //                                 background-color: #171717;
    //                             }
    //                         }

    //                         .item {
    //                             width: formatPX_1920(470px);
    //                             height: formatPX_1920(67px);
    //                             background-color: #171717;
    //                             border-bottom: formatPX_1920(2px) solid #999;
    //                             display: flex;
    //                             align-items: center;
    //                             flex-shrink: 0;
    //                             padding-left: formatPX_1920(45px);

    //                             span {
    //                                 font-family: HurmeGeometricSans3-SemiBold;
    //                                 font-size: formatPX_1920(24px);
    //                                 margin-left: formatPX_1920(20px);
    //                             }
    //                         }
    //                     }

    //                     .product_title_tag {
    //                         position: absolute;
    //                         left: formatPX_1920(360px);
    //                         top: formatPX_1920(140px);
    //                         width: formatPX_1920(588px);
    //                         height: formatPX_1920(182px);
    //                     }
    //                 }
    //             }


    //             .product_wrap {
    //                 width: 100%;
    //                 text-align: left;
    //                 padding-left: formatPX_1920(360px);

    //                 .product_item {
    //                     height: formatPX_1920(500px);
    //                     display: flex;
    //                     margin-top: formatPX_1920(80px);

    //                     &:first-child {
    //                         margin-top: 0
    //                     }

    //                     >img {
    //                         width: formatPX_1920(740px);
    //                         height: formatPX_1920(500px);
    //                     }

    //                     .product_intro {
    //                         display: flex;
    //                         flex-direction: column;
    //                         align-items: center;
    //                         border-bottom: formatPX_1920(2px) solid #999;

    //                         .product_name {
    //                             font-family: PingFang-SC-Bold;
    //                             font-size: formatPX_1920(30px);
    //                             height: formatPX_1920(67px);
    //                             line-height: formatPX_1920(67px);

    //                             span {
    //                                 font-family: HurmeGeometricSans3-SemiBold;
    //                                 font-size: formatPX_1920(24px);
    //                                 margin-left: formatPX_1920(20px);
    //                             }
    //                         }

    //                         .en {
    //                             font-family: HurmeGeometricSans3-Light;
    //                             font-size: formatPX_1920(24px);
    //                             margin-bottom: formatPX_1920(28px);
    //                         }
    //                     }

    //                     &:nth-child(odd) {
    //                         .product_intro {
    //                             width: formatPX_1920(450px);
    //                             height: formatPX_1920(470px);
    //                             padding-top: formatPX_1920(42.5px);
    //                             margin-left: formatPX_1920(30px);

    //                             .product_name,
    //                             .en,
    //                             img {
    //                                 width: formatPX_1920(380px);
    //                             }

    //                             .en {
    //                                 line-height: formatPX_1920(28px);
    //                             }

    //                             img {
    //                                 height: formatPX_1920(260px);
    //                             }
    //                         }
    //                     }

    //                     &:nth-child(even) {
    //                         .product_intro {
    //                             width: formatPX_1920(470px);
    //                             height: formatPX_1920(500px);
    //                             padding-top: formatPX_1920(5px);
    //                             margin-left: formatPX_1920(20px);

    //                             .product_name,
    //                             .en {
    //                                 width: formatPX_1920(380px);
    //                             }

    //                             img {
    //                                 width: formatPX_1920(280px);
    //                                 height: formatPX_1920(358px);
    //                             }
    //                         }
    //                     }

    //                     &:nth-child(1) {
    //                         .en {
    //                             letter-spacing: formatPX_1920(3.6px);
    //                         }
    //                     }

    //                     &:nth-child(2) {
    //                         .en {
    //                             letter-spacing: formatPX_1920(1.8px);
    //                         }
    //                     }

    //                     &:nth-child(3) {
    //                         .en {
    //                             letter-spacing: formatPX_1920(2.4px);
    //                         }
    //                     }

    //                     &:nth-child(4) {
    //                         .en {
    //                             letter-spacing: formatPX_1920(3.3px);
    //                         }
    //                     }

    //                     &:nth-child(5) {
    //                         .en {
    //                             letter-spacing: formatPX_1920(3.3px);
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         .doit_wrap {
    //             position: relative;
    //             width: 100%;
    //             height: 100vh;

    //             .wrapper {
    //                 position: relative;
    //                 left: 0;
    //                 top: 0;
    //                 width: 100%;
    //                 height: 100vh;
    //                 background-color: transparent;
    //                 overflow: hidden;

    //                 img {
    //                     height: formatPX_1920(1885px);
    //                     height: formatPX_1920(238px);

    //                     &:first-child {
    //                         transform: translate3d(100%, 0, 0);
    //                     }

    //                     &:last-child {
    //                         transform: translate3d(-100%, 0, 0);
    //                     }
    //                 }
    //             }

    //         }
    //     }
    // }
    .product_wrap {
        position: relative;
        color: #fff;
        background-color: #171717;
        text-align: left;
        padding: formatPX_1920(380px) 0 formatPX_1920(180px) formatPX_1920(360px);

        .title_wrap {
            position: absolute;
            top: 0;
            left: 0;
            bottom: calc(100vh - formatPX_1920(380px));
            z-index: 1;
            width: 100%;

            .temp_bg {
                position: sticky;
                top: 0;
                width: 100%;
                height: formatPX_1920(122px);
                background-color: #171717;
                pointer-events: auto;
            }

            .title_tag_wrap {
                position: sticky;
                top: formatPX_1920(118px);
                width: formatPX_1920(1100px);
                height: formatPX_1920(260px);
                background-color: #171717;
                pointer-events: auto;
                padding-left: formatPX_1920(360px);

                .title_tag {
                    width: formatPX_1920(677px);
                    height: formatPX_1920(178px);
                }

            }
        }

        .product_item {
            padding-bottom: formatPX_1920(80px);

            &:last-child {
                padding-bottom: 0;
            }

            .poster {
                width: formatPX_1920(740px);
                height: formatPX_1920(500px);
            }

            .name_wrap {
                position: absolute;
                right: formatPX_1920(330px);
                width: formatPX_1920(470px);

                .cn {
                    position: sticky;
                    z-index: 1;
                    width: 100%;
                    height: formatPX_1920(70px);
                    line-height: formatPX_1920(70px);
                    font-family: PingFang-Blod;
                    font-size: formatPX_1920(30px);
                    background-color: #171717;
                    padding-left: formatPX_1920(46px);

                    span {
                        font-family: HurmeGeometricSans3-SemiBold;
                        font-size: formatPX_1920(24px);
                    }
                }

                .cn_1 {
                    top: formatPX_1920(120px);
                }

                .line_1 {
                    top: formatPX_1920(188px);
                }

                .cn_2 {
                    top: formatPX_1920(188px);
                }

                .line_2 {
                    top: formatPX_1920(256px);
                }

                .cn_3 {
                    top: formatPX_1920(256px);
                }

                .line_3 {
                    top: formatPX_1920(324px);
                }

                .cn_4 {
                    top: formatPX_1920(324px);
                }

                .line_4 {
                    top: formatPX_1920(392px);
                }

                .cn_5 {
                    top: formatPX_1920(392px);
                }

                .line_5 {
                    top: formatPX_1920(460px);
                }

                .en {
                    width: formatPX_1920(374px);
                    line-height: formatPX_1920(28px);
                    font-family: HurmeGeometricSans3-Light;
                    font-size: formatPX_1920(24px);
                }

                .small_img {
                    margin: formatPX_1920(26px) 0 formatPX_1920(16px);
                }

                .small_img_1,
                .small_img_3,
                .small_img_5 {
                    width: formatPX_1920(380px);
                    height: formatPX_1920(260px);
                    box-sizing: content-box;
                    padding-top: formatPX_1920(41px);
                }

                .small_img_2,
                .small_img_4 {
                    width: formatPX_1920(280px);
                    height: formatPX_1920(358px);
                }

                .line {
                    position: sticky;
                    z-index: 2;
                    width: 100%;
                    border-top: 2PX solid #999999;
                }

            }

            .name_wrap_1 {
                top: formatPX_1920(380px);
                bottom: calc(100vh - formatPX_1920(190px));
            }

            .name_wrap_2 {
                top: formatPX_1920(960px);
                bottom: calc(100vh - formatPX_1920(260px));
            }

            .name_wrap_3 {
                top: formatPX_1920(1540px);
                bottom: calc(100vh - formatPX_1920(330px));
            }

            .name_wrap_4 {
                top: formatPX_1920(2120px);
                bottom: calc(100vh - formatPX_1920(400px));
            }

            .name_wrap_5 {
                top: formatPX_1920(2700px);
                bottom: calc(100vh - formatPX_1920(470px));
            }
        }
    }

    .doit_wrap {
        position: relative;
        width: 100%;
        height: 150vh;
        background-color: #171717;

        .wrapper {
            position: relative;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            background-color: transparent;
            overflow: hidden;

            img {
                height: formatPX_1920(1885px);
                height: formatPX_1920(238px);

                &:first-child {
                    transform: translate3d(100%, 0, 0);
                }

                &:last-child {
                    transform: translate3d(-100%, 0, 0);
                }
            }
        }

    }

    .peripheral_container {
        position: relative;
        background-color: #171717;

        .peripheral_wrap {
            padding-top: formatPX_1920(100px);

            .peripheral_title_tag {
                width: formatPX_1920(614px);
                height: formatPX_1920(164px);
                margin-left: formatPX_1920(138px);
            }

            .peripheral_img_wrap {
                height: formatPX_1920(690px);
                padding: 0 formatPX_1920(210px) 0 formatPX_1920(99px);
                margin: formatPX_1920(28px) 0 formatPX_1920(100px);

                .rotate_tag {
                    width: formatPX_1920(199px);
                    height: formatPX_1920(199px);
                }

                .peripheral_img {
                    width: formatPX_1920(1182px);
                    height: 100%;
                }
            }

            .peripheral_product_container {
                height: 100vh;

                .temp_wrapper {
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100vh;
                    padding: 0 formatPX_1920(140px);
                }

                .wrapper {
                    padding-left: formatPX_1920(832px);
                    overflow: hidden;

                    .peripheral_product_wrap {
                        width: formatPX_1920(356px);
                        height: formatPX_1920(466px);

                        .peripheral_swiper {
                            overflow: visible;

                            .peripheral_swiper_slide {
                                position: relative;
                                margin-right: formatPX_1920(60px);

                                &.swiper-slide-active {
                                    margin-right: 500px !important;

                                    .periphera_item {
                                        z-index: 1;
                                        transform: translate3d(formatPX_1920(192px), 0, 0) scale(1.729);
                                        /* width: formatPX_1920(616px);
                                            height: formatPX_1920(806px); */
                                    }

                                }

                                .periphera_item {
                                    width: 100%;
                                    height: 100%;
                                    color: #fff;
                                    font-family: HurmeGeometricSans3-Bold;
                                    font-size: formatPX_1920(20px);
                                    background-size: 100% 100%;
                                    transform-origin: 0 100%;
                                    transition: all 0.5s;
                                    padding: 0 0 formatPX_1920(14px) formatPX_1920(14px);

                                    &.top_speed {
                                        transition-duration: 0s !important;
                                    }

                                    .cn {
                                        position: relative;
                                        font-family: SourceHanSansCN-Medium;
                                        margin: formatPX_1920(15px) 0;

                                        &::before {
                                            position: absolute;
                                            right: formatPX_1920(-10px);
                                            bottom: 0;
                                            content: '';
                                            width: formatPX_1920(3px);
                                            height: formatPX_1920(3px);
                                            background-color: #fff;
                                        }
                                    }

                                    .price {
                                        font-size: formatPX_1920(18px);
                                    }
                                }
                            }
                        }

                    }
                }

            }
        }
    }

    .series_wrap {
        position: relative;
        height: formatPX_1920(1080px);
        background-size: 100% 100%;

        .more_btn {
            position: absolute;
            left: formatPX_1920(360px);
            bottom: formatPX_1920(388px);
            width: formatPX_1920(120px);
            height: formatPX_1920(36px);
        }
    }

    .news_wrap,
    .hot_recommend_wrap {
        background-color: #fff;
        .title_wrap {
            font-family: HurmeGeometricSans3-Black;
            font-size: formatPX_1920(48px);
            padding-top: formatPX_1920(86px);

            span:last-child {
                font-family: SourceHanSansCN-Medium;
                font-size: formatPX_1920(26px);
                color: #2C2C2C;
                margin-top: formatPX_1920(12px);
            }
        }
    }

    .news_wrap {
        .news_content {
            width: formatPX_1920(1350px);
            margin: formatPX_1920(130px) auto 0;
            border-top: formatPX_1920(1px) solid #111111;
            padding-bottom: formatPX_1920(147px);

            .news_item {
                height: formatPX_1920(452px);
                border-bottom: formatPX_1920(1px) solid #111111;

                .news_detail {
                    position: relative;
                    width: formatPX_1920(550px);
                    height: formatPX_1920(374px);
                    text-align: left;

                    &::before {
                        position: absolute;
                        right: formatPX_1920(-254px);
                        top: 50%;
                        transform: translateY(-50%);
                        content: '';
                        width: formatPX_1920(2px);
                        height: formatPX_1920(356px);
                        background-color: #CECECE;
                    }

                    .time {
                        font-family: HurmeGeometricSans3-Light;
                        font-size: formatPX_1920(24px);
                        color: #999999;
                    }

                    .title {
                        width: 100%;
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(30px);
                        margin: formatPX_1920(120px) 0 formatPX_1920(55px);
                        @include no-wrap();
                    }

                    .content {
                        line-height: formatPX_1920(38px);
                        font-family: SourceHanSansCN-Regular;
                        font-size: formatPX_1920(18px);
                        white-space: pre-wrap;
                        @include no-wrap-multi(3);
                    }

                    .to_right_tag {
                        position: absolute;
                        right: formatPX_1920(7px);
                        bottom: formatPX_1920(8px);
                        width: formatPX_1920(20px);
                        height: formatPX_1920(29px);
                    }
                }

                .new_img {
                    width: formatPX_1920(526px);
                    height: formatPX_1920(374px);
                    margin-left: auto;
                }
            }
        }
    }

    .hot_recommend_wrap {
        .hot_recommend_content {
            position: relative;
            width: formatPX_1920(1200px);
            height: formatPX_1920(774px);
            overflow: hidden;
            margin: formatPX_1920(134px) auto 0;

            .hot_swiper {
                height: formatPX_1920(426px);
                overflow: visible;

                .hot_swiper_slide {
                    position: relative;

                    &.swiper-slide-active {
                        .hot_img {
                            width: formatPX_1920(384px);
                            height: formatPX_1920(426px);
                        }
                    }

                    .hot_img {
                        width: formatPX_1920(344px);
                        height: formatPX_1920(382px);
                        transition: all 0.5s;
                    }
                }

                ::v-deep.swiper-pagination {
                    bottom: formatPX_1920(-88px);

                    .swiper-pagination-bullet {
                        width: formatPX_1920(12px);
                        height: formatPX_1920(12px);
                        border: formatPX_1920(1px) solid #fff;
                        background-color: #CECECE;
                        opacity: 1;
                    }

                    .swiper-pagination-bullet-active {
                        background-color: #FF4400;
                    }
                }
            }

            .next_btn,
            .prev_btn {
                position: absolute;
                top: formatPX_1920(470px);
                z-index: 1;
                width: formatPX_1920(68px);
                height: formatPX_1920(68px);
            }

            .next_btn {
                right: 0;
            }

            .prev_btn {
                left: 0;
            }
        }
    }
}</style>