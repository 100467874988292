<template>
    <div id="app" :class="platform =='pc'?'pc':'mobile'">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { newsDataCosUrl } from '@/config/evn.js'
    export default {
        data() {
            return {
                // _beforeUnload_time: 0,
                // _gap_time: 0
            }
        },
        created() {
            // 获取时事新闻数据并保存在store中
            this.$ajax.get(newsDataCosUrl).then((res) => {
                let newsData = res.data.newsData
                this.$store.commit('setNewsData', newsData)
            }, (err) => {
                console.log(err)
            })
        },
        // mounted() {
        //     window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        //     window.addEventListener('unload', e => this.unloadHandler(e))
        // },
        // destroyed() {
        //     window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
        //     window.removeEventListener('unload', e => this.unloadHandler(e))
        // },
        computed: {
            ...mapState(['platform'])
        },
        methods: {
            // beforeunloadHandler() {
            //     this._beforeUnload_time = new Date().getTime();
            // },
            // unloadHandler(e) {
            //     this._gap_time = new Date().getTime() - this._beforeUnload_time;
            //     //判断是窗口关闭还是刷新
            //     if (this._gap_time <= 5) {
            //         // 窗口关闭
            //         this.$handleLocalStorage('remove', 'isShowedWelcome')
            //     }
            // }
        }
    }
</script>

<style lang="scss">
    @import '@/style/mixin.scss';

    #app {
        font-family: SourceHanSansCN-Medium, Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        font-weight: normal;
        // overflow: hidden;
        color: #111111;
    }

    .clickable {
        cursor: pointer;
    }

    .pc {
        font-size: formatPX_1920(28px);
    }

    .mobile {
        font-size: 28px;
        overflow: hidden;
    }
</style>