<template>
    <div class="custom_head_cpe absolute_l_t">
        <img class="logo clickable" src="@/assets/logo.png" @click="$gotoPage('home')" alt="">
        <div class="menu_btn flex_col flex_justify_between clickable" @click="isShowMenu = true">
            <span></span>
            <span></span>
        </div>
        <div class="menu_wrap fixed_l_t" v-show="isShowMenu">
            <div class="close_wrap clickable" :class="closeActived?'actived':''" @click="isShowMenu = false">
                <span></span>
                <span></span>
            </div>
            <div class="menu_content flex_col flex_align_start absolute_t_center">
                <div class="nav_item clickable" v-for="(item, index) of navList" :key="index" @click="handleSelectNav(item.pageName)">
                    <span class="en">{{item.en}}</span>
                    <span class="cn">{{item.cn}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState} from 'vuex';
    export default {
        data() {
            return {
                isShowMenu: false,
                closeActived: false,
                navList: [
                    {
                        pageName: 'home',
                        en: 'Home',
                        cn: '首页'
                    }, {
                        pageName: 'periphery',
                        en: 'Periphery',
                        cn: '周边'
                    }, {
                        pageName: 'news',
                        en: 'Topical News',
                        cn: '时事新闻'
                    }, {
                        pageName: 'newProducts',
                        en: 'New Products',
                        cn: '新品'
                    }, {
                        pageName: 'about',
                        en: 'About GETO',
                        cn: '关于解套'
                    }
                ]
            }
        },
        computed: {
            ...mapState([
                'curPageName'
            ])
        },
        watch: {
            isShowMenu: {
                handler(newVal, oldVal) {
                    if (newVal) {
                        setTimeout(() => {
                            this.closeActived = true
                        }, 0);
                    } else {
                        this.closeActived = false
                    }
                }
            }
        },
        methods: {
            handleSelectNav(pagename) {
                this.$gotoPage(pagename)
                this.isShowMenu = false
                this.isShowMenu = false
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/style/mixin.scss';
    .custom_head_cpe {
        z-index: 2;
        width: 100%;
        /* height: formatPX_1920(88px); */

        .logo {
            position: absolute;
            left: formatPX_1920(210px);
            top: formatPX_1920(26px);
            width: formatPX_1920(79px);
            height: formatPX_1920(24px);
        }

        .menu_btn {
            @include extend-click();
            position: absolute;
            right: formatPX_1920(50px);
            top: formatPX_1920(34px);
            width: formatPX_1920(50px);
            height: formatPX_1920(20px);


            span {
                width: 100%;
                height: formatPX_1920(4px);
                background-color: #fff;
            }
        }
        .menu_wrap {
            width: 100%;
            height: 100vh;
            color: #fff;
            background-color: #171717;
            .close_wrap {
                position: absolute;
                right: formatPX_1920(284px);
                top: formatPX_1920(97px);
                width: formatPX_1920(66px);
                height: formatPX_1920(66px);
                &.actived {
                    span:first-child {
                        top: formatPX_1920(31px);
                        transform: rotate(45deg);
                    }
                    span:last-child {
                        bottom: formatPX_1920(31px);
                        transform: rotate(-45deg);
                    }
                }
                span {
                    position: absolute;
                    left: formatPX_1920(-13px);
                    width: formatPX_1920(92px);
                    height: formatPX_1920(4px);
                    background-color: #fff;
                    transition: all 0.5s ease;
                    &:first-child {
                        top: formatPX_1920(20px);
                    }
                    &:last-child {
                        bottom: formatPX_1920(20px);
                    }
                }
            }
            .menu_content {
                left: formatPX_1920(676px);
                .nav_item {
                position: relative;
                font-family: HurmeGeometricSans3-Black;
                font-size: formatPX_1920(68px);
                line-height: formatPX_1920(82px);
                margin-top: formatPX_1920(60px);
                &:hover {
                    color: #0000FF;
                    .cn {
                        display: block;
                    }
                }
                &:first-child {
                    margin-top: 0;
                }
                .cn {
                    position: absolute;
                    left: 100%;
                    bottom: formatPX_1920(12px);
                    display: none;
                    line-height: 1;
                    font-family: SourceHanSansCN-Medium;
                    font-size: formatPX_1920(30px);
                    color: #fff;
                    white-space:nowrap;
                    border-bottom: formatPX_1920(3px) solid #fff;
                    transform: translate3d(formatPX_1920(30px), 0, 0);
                    padding: 0 formatPX_1920(8px) formatPX_1920(5px);
                }
            }
            }
        }
    }
</style>