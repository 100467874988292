import customHead from './customHead.vue'
import customFoot from './customFoot.vue'

const commonComponents = {
    install: function(Vue) {
        Vue.component('customHead', customHead)
        Vue.component('customFoot', customFoot)
    }
}

export default commonComponents