<template>
    <div class="welcome_cpe w_h_100" :style="{ width: clientWidth + 'px', height: clientHeight + 'px' }">
        <div class="flow_circle_wrap absolute_l_t" :style="{ left: circleLeft + 'px', top: circleTop + 'px' }"
            @mousedown.stop="move($event)">
            <flow-circle></flow-circle>
        </div>
        <div class="wrapper flex_col absolute_t_center">
            <h1>DO IT FOR CHANGE</h1>
            <h2>Positive optimism</h2>
            <div class="button flex_center_center" @click="handleEnter">进入网站</div>
        </div>
    </div>
</template>

<script>
    import flowCircle from '@/components/pc/flowCircle';
    export default {
        data() {
            return {
                circleLeft: 80,
                circleTop: (window.innerHeight || document.documentElement.clientHeight)*0.05,
                clientHeight: window.innerHeight || document.documentElement.clientHeight, // 浏览器可见区域高度
                clientWidth: window.innerWidth || document.documentElement.clientWidth, // 浏览器可见区域宽度
            }
        },
        mounted() {
            window.addEventListener('resize', this.resize)
        },
        destroyed() {
            window.removeEventListener('resize', this.resize)
        },
        computed: {
            // 极限Left值
            limitLeft() {
                return this.clientWidth - this.clientHeight*0.9 // flowCircle宽为this.clientHeight*0.9
            },
            // 极限Top值
            limitTop() {
                return this.clientHeight*0.1 // flowCircle高为this.clientHeight*0.9
            }
        },
        methods: {
            resize() {
                this.clientHeight = window.innerHeight || document.documentElement.clientHeight
                this.clientWidth = window.innerWidth || document.documentElement.clientWidth
            },
            handleEnter() {
                this.$emit('handleEnter')
            },
            move(e) {
                let element = e.currentTarget;

                //算出鼠标相对元素的位置
                let disX = e.clientX;
                let disY = e.clientY;

                let beforeLeft = this.circleLeft
                let beforeTop= this.circleTop
                let limitLeft = this.limitLeft
                let limitTop = this.limitTop
                let x, y


                document.onmousemove = (e) => {
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = (e.clientX - disX)*0.6;
                    let top = (e.clientY - disY)*0.6;
                    //移动当前元素
                    x = beforeLeft+left
                    y = beforeTop+top
                    if (x < 0) {
                        this.circleLeft = 0
                    } else if (x > limitLeft) {
                        this.circleLeft = limitLeft
                    } else {
                        this.circleLeft = x
                    }
                    if (y < 0) {
                        this.circleTop = 0
                    } else if (y > limitTop) {
                        this.circleTop = limitTop
                    } else {
                        this.circleTop = y
                    }
                };
                document.onmouseup = (e) => {
                    //鼠标弹起来的时候不再移动
                    document.onmousemove = null;
                    //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
                    document.onmouseup = null;
                };
            }
        },
        components: {
            flowCircle
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/style/mixin.scss';

    .welcome_cpe {
        cursor: url('~@/assets/pc/img/cursor_1.png') 24 24, auto !important;
        background: #171717;

        .flow_circle_wrap {
            /* left: formatPX_1920(200px); */
            /* transition: all 0.1s; */
            transform: translate3d(0, 0, 0);
        }

        .wrapper {
            left: formatPX_1920(958px);
            z-index: 1;
            color: #fff;
            text-align: left;

            h1 {
                font-family: HurmeGeometricSans3-Black;
                font-size: formatPX_1920(38px);
            }

            h2 {
                font-family: HurmeGeometricSans3-Bold;
                margin: formatPX_1920(25px) 0 formatPX_1920(66px);
            }

            .button {
                width: formatPX_1920(138px);
                height: formatPX_1920(42px);
                font-size: formatPX_1920(24px);
                border: formatPX_1920(1px) solid #fff;
                border-radius: formatPX_1920(32px);
            }
        }
    }
</style>