let baseCosBaseUrl = 'https://ledo-xt-1300835910.cos.ap-guangzhou.myqcloud.com/website/' // 手机与pc端共用目录
let mobileCosBaseUrl = 'https://ledo-xt-1300835910.cos.ap-guangzhou.myqcloud.com/website/mobile/' // 手机端腾讯cos图片文件夹目录
let pcCosBaseUrl = 'https://ledo-xt-1300835910.cos.ap-guangzhou.myqcloud.com/website/pc/' // pc端腾讯cos图片文件夹目录
let newsDataCosUrl = 'https://ledo-xt-1300835910.cos.ap-guangzhou.myqcloud.com/website/news/newsData.json' // 时事新闻数据文件目录
let pcJDShopHomeUrl = 'https://mall.jd.com/index-1000395224.html' // pc端京东店铺首页url
let mobileJDShopHomeUrl = 'https://mall.jd.com/index-1000395224.html' // mobile端京东店铺首页url
let pcWBUrl = 'https://weibo.com/u/7402562420' // pc端微博url
let mobileWBUrl = 'https://m.weibo.cn/u/7402562420?jumpfrom=weibocom' // mobile端微博url
export {
    mobileCosBaseUrl,
    pcCosBaseUrl,
    baseCosBaseUrl,
    newsDataCosUrl,
    pcJDShopHomeUrl,
    mobileJDShopHomeUrl,
    pcWBUrl,
    mobileWBUrl
}